<template>
	<Component
		:is="component"
		v-if="component"
		:settings="activeModalSettings"
	/>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { type Component } from 'vue';

import AddBlockModal from '@/components/builder-modals/modals/AddBlockModal.vue';
import AddPageModal from '@/components/builder-modals/modals/AddPageModal.vue';
import AssetManager from '@/components/builder-modals/modals/AssetManager.vue';
import BeforePublishModal from '@/components/builder-modals/modals/BeforePublishModal.vue';
import BlogPostSettingsModal from '@/components/builder-modals/modals/BlogPostSettingsModal.vue';
import BlogSettingsModal from '@/components/builder-modals/modals/BlogSettingsModal.vue';
import ChooseBlockPlacementModal from '@/components/builder-modals/modals/ChooseBlockPlacementModal.vue';
import ConnectSubscriptionModal from '@/components/builder-modals/modals/ConnectSubscriptionModal.vue';
import EditOnlineStoreModal from '@/components/builder-modals/modals/EditOnlineStoreModal.vue';
import LinkSettingsModal from '@/components/builder-modals/modals/LinkSettingsModal.vue';
import PublishModalOption from '@/components/builder-modals/modals/PublishModalOption.vue';
import PublishedModal from '@/components/builder-modals/modals/PublishedModal.vue';
import PublishedModalRoot from '@/components/builder-modals/modals/PublishedModalRoot.vue';
import PublishedModalUpdateSection from '@/components/builder-modals/modals/PublishedModalUpdateSection.vue';
import SubscriptionExpiredModal from '@/components/builder-modals/modals/SubscriptionExpiredModal.vue';
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import UnsavedChangesModal from '@/components/builder-modals/modals/UnsavedChangesModal.vue';
import UpgradePlanEcommerce from '@/components/builder-modals/modals/UpgradePlanEcommerce.vue';
import UserTypographyResetAllChangesModal from '@/components/builder-modals/modals/UserTypographyResetAllChangesModal.vue';
import UserTypographyStylesResetModal from '@/components/builder-modals/modals/UserTypographyStylesResetModal.vue';
import LegalContentGeneratorModal from '@/components/builder-modals/modals/LegalContentGeneratorModal.vue';
import BuyCreditsModal from '@/components/builder-modals/modals/BuyCreditsModal.vue';
import GeneratePageModal from '@/components/builder-modals/modals/GeneratePageModal.vue';
import GenerateSectionModal from '@/components/builder-modals/modals/GenerateSectionModal.vue';
import NoBackupsModal from '@/components/builder-modals/modals/NoBackupsModal.vue';
import CreateQrModal from '@/components/builder-modals/modals/CreateQrModal.vue';
import QrNotAvailableModal from '@/components/builder-modals/modals/QrNotAvailableModal.vue';
import WebsiteEditModal from '@/components/builder-modals/modals/WebsiteEditModal.vue';
import UpgradeToBusinessPlanModal from '@/components/builder-modals/modals/UpgradeToBusinessPlanModal.vue';
import CheckoutSlugErrorModal from '@/components/builder-modals/modals/CheckoutSlugErrorModal.vue';
import WordPressExportingModal from '@/components/builder-modals/modals/WordPressExportingModal.vue';
import WordPressExportCompleteModal from '@/components/builder-modals/modals/WordPressExportCompleteModal.vue';
import WordPressExportFailModal from '@/components/builder-modals/modals/WordPressExportFailModal.vue';

import {
	MODAL_USER_TYPOGRAPHY_STYLES_RESET,
	MODAL_USER_TYPOGRAPHY_RESET_ALL_CHANGES,
	MODAL_EDIT_ONLINE_STORE,
	MODAL_ADD_BLOCK,
	MODAL_ADD_PAGE,
	MODAL_BLOG_POST_SETTINGS,
	MODAL_LINK_SETTINGS,
	MODAL_BLOG_SETTINGS,
	MODAL_SUBSCRIPTION_EXPIRED,
	MODAL_BEFORE_PUBLISH,
	MODAL_PUBLISHED,
	MODAL_CONNECT_SUBSCRIPTION,
	MODAL_ASSET_MANAGER,
	MODAL_CHOOSE_BLOCK_PLACEMENT,
	MODAL_PUBLISHED_ROOT,
	MODAL_PUBLISHED_UPDATE_SECTION,
	MODAL_PUBLISH_OPTION,
	MODAL_SYSTEM_DIALOG,
	MODAL_UNSAVED_CHANGES,
	MODAL_ECOMMERCE_UPGRADE_PLAN,
	MODAL_LEGAL_CONTENT_GENERATOR,
	MODAL_BUY_CREDITS,
	MODAL_GENERATE_PAGE,
	MODAL_GENERATE_SECTION,
	MODAL_NO_BACKUPS_MODAL,
	MODAL_CREATE_QR,
	MODAL_QR_NOT_AVAILABLE,
	MODAL_WEBSITE_EDIT,
	MODAL_UPGRADE_TO_BUSINESS_PLAN,
	MODAL_CHECKOUT_SLUG_ERROR,
	MODAL_WORDPRESS_EXPORTING,
	MODAL_WORDPRESS_EXPORT_COMPLETE,
	MODAL_WORDPRESS_EXPORT_FAIL,
} from '@/constants';

import {
	ref,
	computed,
	watch,
} from 'vue';

const { state } = useStore();

interface componentInterface {
	[key: string]: Component
}

const availableComponents: componentInterface = {
	[MODAL_USER_TYPOGRAPHY_STYLES_RESET]: UserTypographyStylesResetModal,
	[MODAL_USER_TYPOGRAPHY_RESET_ALL_CHANGES]: UserTypographyResetAllChangesModal,
	[MODAL_WEBSITE_EDIT]: WebsiteEditModal,
	[MODAL_EDIT_ONLINE_STORE]: EditOnlineStoreModal,
	[MODAL_ADD_BLOCK]: AddBlockModal,
	[MODAL_ADD_PAGE]: AddPageModal,
	[MODAL_BLOG_POST_SETTINGS]: BlogPostSettingsModal,
	[MODAL_BLOG_SETTINGS]: BlogSettingsModal,
	[MODAL_SUBSCRIPTION_EXPIRED]: SubscriptionExpiredModal,
	[MODAL_BEFORE_PUBLISH]: BeforePublishModal,
	[MODAL_PUBLISHED]: PublishedModal,
	[MODAL_CONNECT_SUBSCRIPTION]: ConnectSubscriptionModal,
	[MODAL_ASSET_MANAGER]: AssetManager,
	[MODAL_CHOOSE_BLOCK_PLACEMENT]: ChooseBlockPlacementModal,
	[MODAL_PUBLISHED_ROOT]: PublishedModalRoot,
	[MODAL_PUBLISHED_UPDATE_SECTION]: PublishedModalUpdateSection,
	[MODAL_PUBLISH_OPTION]: PublishModalOption,
	[MODAL_SYSTEM_DIALOG]: SystemDialogModal,
	[MODAL_UNSAVED_CHANGES]: UnsavedChangesModal,
	[MODAL_ECOMMERCE_UPGRADE_PLAN]: UpgradePlanEcommerce,
	[MODAL_LEGAL_CONTENT_GENERATOR]: LegalContentGeneratorModal,
	[MODAL_BUY_CREDITS]: BuyCreditsModal,
	[MODAL_GENERATE_PAGE]: GeneratePageModal,
	[MODAL_GENERATE_SECTION]: GenerateSectionModal,
	[MODAL_NO_BACKUPS_MODAL]: NoBackupsModal,
	[MODAL_LINK_SETTINGS]: LinkSettingsModal,
	[MODAL_CREATE_QR]: CreateQrModal,
	[MODAL_QR_NOT_AVAILABLE]: QrNotAvailableModal,
	[MODAL_UPGRADE_TO_BUSINESS_PLAN]: UpgradeToBusinessPlanModal,
	[MODAL_CHECKOUT_SLUG_ERROR]: CheckoutSlugErrorModal,
	[MODAL_WORDPRESS_EXPORTING]: WordPressExportingModal,
	[MODAL_WORDPRESS_EXPORT_COMPLETE]: WordPressExportCompleteModal,
	[MODAL_WORDPRESS_EXPORT_FAIL]: WordPressExportFailModal,

} as const;

const component = ref<Component | null>(null);

const activeModalName = computed(() => state.gui.activeModalName);
const activeModalSettings = computed(() => state.gui.activeModalSettings);

watch(activeModalName, () => {
	component.value = activeModalName.value ? availableComponents[activeModalName.value] : null;
});
</script>
