import { captureException } from '@sentry/vue';

import {
	META_ECOMMERCE_TYPE,
	ECOMMERCE_TYPE_ZYRO,
} from '@zyro-inc/site-modules/constants';
import { BLOCKS_ECOMMERCE } from '@zyro-inc/site-modules/constants/ecommerce';
import { ECOMMERCE_DYNAMIC_PAGE_ENABLE_TIMESTAMP } from '@/constants';
import {
	getIsLocaleWithEcommerce,
	getIsSiteWithEcommerce,
} from '@zyro-inc/site-modules/utils/getters/getIsLocaleWithEcommerce';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';

import {
	getSettings,
	getStoreProducts,
	getVariantsQuantity,
	getCategories,
} from '@/api/StoreApi';
import { getMessagesByLocale } from '@/i18n/setup';
import { patcher } from '@/utils/jsondiffpatch';
import { getFilteredProductsByCategoryId } from '@/utils/ecommerce';
import {
	removePage,
	removeBlock,
	removeElement,
} from '@/utils/siteDataUtils';
import { productsPerPageByColumnCount } from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { generateRandomId } from '@/utils/generateRandomId';
import { getImageSrc } from '@zyro-inc/site-modules/utils/getImageSrc';
import { useSiteStore } from '@/stores';
import {
	getIsExperimentActive,
	FEATURE_FLAG_IDS,
} from '@/utils/experiments';

const ECOMMERCE_PRICE_MAX_AMOUNT = 100000; // in cents
const ECOMMERCE_PRICE_MIN_AMOUNT = 100; // in cents

const getSortedProductsKey = (sort = '', collectionId = '') => `${sort}${collectionId}`;

export default {
	namespaced: true,
	state: {
		isLoadingProducts: {
			global: false,
		},
		isLoadingCategories: false,
		products: [],
		hiddenProducts: [],
		productsSorted: {},
		countSorted: {},
		categories: [],
		variantsQuantity: [],
		count: 0,
		productMetaUpdates: {},
	},
	getters: {
		isStoreTypeZyro: (state, getters, rootState, rootGetters) => rootGetters
			.siteMeta[META_ECOMMERCE_TYPE] === ECOMMERCE_TYPE_ZYRO,
		isEcommerceAvailableForUsers: (state, getters, rootState, rootGetters) => !rootGetters['subscription/hasActiveEcommerceSubscription'],
		// need to update this place
		isLocaleWithEcommerceItems: (state, getters, rootState, rootGetters) => getIsLocaleWithEcommerce({
			blocks: rootGetters.siteBlocks,
			elements: rootGetters.siteElements,
		}),
		isSiteWithEcommerceItems: () => {
			const siteStore = useSiteStore();

			return getIsSiteWithEcommerce(siteStore.site);
		},
		isEcommerceAvailableForBusinessPlan: (state, getters, rootState, rootGetters) => (rootGetters['subscription/hasActiveBusinessSubscription']),
		isBlockInEcommerceProductPage: (state, getters, rootState, rootGetters) => (blockId) => Object.values(
			rootGetters.ecommerceProductPages,
		).some(({ blocks }) => blocks.includes(blockId)),
		isBlockSingleInEcommerceProductPage: (state, getters, rootState, rootGetters) => (blockId) => Object.values(
			rootGetters.ecommerceProductPages,
		).some(({ blocks }) => blocks.includes(blockId) && blocks.length === 1),
		isCartVisible: (state, getters, rootState, rootGetters) => rootGetters.headerBlock.settings.isCartVisible,
		products: (state) => state.products,
		hiddenProducts: (state) => state.hiddenProducts,
		categories: (state) => state.categories,
		productsSorted: (state) => (sort, collectionId) => {
			const sortValue = (!sort && collectionId) ? 'order=ASC&sort_by=collection_order' : sort;

			return state.productsSorted[getSortedProductsKey(sortValue, collectionId)] || [];
		},
		countSorted: (state) => (sort, collectionId) => {
			const sortValue = (!sort && collectionId) ? 'order=ASC&sort_by=collection_order' : sort;

			return state.countSorted[getSortedProductsKey(sortValue, collectionId)] || 0;
		},
		count: (state) => state.count,
		isDynamicPageFlowEnabled: (state, getters, rootState) => {
			const isFeatureFlagEnabled = getIsExperimentActive(FEATURE_FLAG_IDS.ECOMMERCE_DYNAMIC_PAGES_FLOW);

			const siteCreationTimestamp = Math.floor(new Date(rootState.createdAt).getTime() / 1000);
			const isFlowEnabledByTimestamp = siteCreationTimestamp > ECOMMERCE_DYNAMIC_PAGE_ENABLE_TIMESTAMP;

			return isFeatureFlagEnabled && isFlowEnabledByTimestamp;
		},
	},
	mutations: {
		setStoreProducts(state, {
			products,
			sort,
			collectionId,
			count,
		}) {
			if (collectionId || sort) {
				state.productsSorted[getSortedProductsKey(sort, collectionId)] = products;
				state.countSorted[getSortedProductsKey(sort, collectionId)] = count;
			} else {
				state.products = products;
				state.count = count;
			}
		},
		setStoreHiddenProducts(state, { products }) {
			state.hiddenProducts = products;
		},
		setCategories(state, categories) {
			state.categories = categories;
		},
		setVariantsQuantity(state, variantsQuantity) {
			state.variantsQuantity = variantsQuantity;
		},
		setIsLoadingProducts(state, {
			blockId,
			isLoading,
		}) {
			state.isLoadingProducts[blockId] = isLoading;
		},
		setIsLoadingCategories(state, { isLoading }) {
			state.isLoadingCategories = isLoading;
		},
	},
	actions: {
		setProductMetaUpdates: ({ state }, metaUpdates) => {
			if (!metaUpdates) {
				state.productMetaUpdates = {};

				return;
			}

			const {
				productId,
				productMeta,
			} = metaUpdates;

			if (!state.productMetaUpdates[productId]) {
				state.productMetaUpdates[productId] = {};
			}

			state.productMetaUpdates[productId] = {
				...state.productMetaUpdates[productId],
				...productMeta,
			};
		},
		getSettings: async ({
			rootGetters,
			dispatch,
		}) => {
			const storeId = getStoreId(rootGetters.siteMeta);

			if (!storeId) {
				return;
			}

			try {
				const settings = await getSettings(storeId);
				const storeLanguage = settings.store_owner.language?.toLowerCase();
				let messages = await getMessagesByLocale(storeLanguage);

				if (!messages?.onlineStore) {
					messages = await getMessagesByLocale('en');
				}

				dispatch('addEcommerceShoppingCart', {
					translations: messages.onlineStore,
					lang: storeLanguage,
				}, {
					root: true,
				});
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while getting settings.',
				}, {
					root: true,
				});

				captureException(error);
			}
		},
		getProducts: async ({
			getters,
			commit,
			dispatch,
			rootGetters,
		}, {
			sortType,
			collectionId,
			blockId = 'global',
			resetUndoForPageCreation = true,
			pickStylesFromTheme = false,
			shouldAwaitPageCreation = false,
		} = {}) => {
			const storeId = getStoreId(rootGetters.siteMeta);

			if (!storeId) {
				return;
			}

			try {
				commit('setIsLoadingProducts', {
					blockId,
					isLoading: true,
				});

				const sort = (!sortType && collectionId) ? 'order=ASC&sort_by=collection_order' : sortType;
				const productData = await getStoreProducts(storeId, {
					sort,
					collectionId,
				});

				commit('setStoreProducts', {
					products: productData.products,
					sort,
					collectionId,
					count: productData.count,
				});

				Object.keys(rootGetters.siteBlocks).forEach((key) => {
					const block = rootGetters.siteBlocks[key];

					// this is used for old blocks which stored variantId, instead of productId
					if (block.type === 'BlockEcommerceProduct') {
						const variantBasedProduct = productData.products
							.find((product) => product.variants.find((variant) => variant.id === block.product.id));

						if (variantBasedProduct) {
							dispatch('updateBlockData', {
								blockId: key,
								blockData: {
									...block,
									product: {
										id: variantBasedProduct.id,
									},
								},
							}, {
								root: true,
							});
						}
					}

					if (block.type === 'BlockEcommerceProductList') {
						const filteredProducts = !block.productCategoryId
							? getters.products
							: getFilteredProductsByCategoryId(getters.products, block.productCategoryId);

						dispatch('updateBlockData', {
							blockId: key,
							blockData: {
								...block,
								productIds: filteredProducts.map((product) => product.id),
								productsPerPage: block.productsPerPage || productsPerPageByColumnCount[2],
							},
						}, {
							root: true,
						});
					}
				});

				if (!getters.isStoreTypeZyro) {
					return;
				}

				// Might not have all the products with these params
				// so we want to call addEcommerceProductPages only when all products are fetched
				if (!sortType && !collectionId) {
					if (shouldAwaitPageCreation) {
						await dispatch('addEcommerceProductPages', {
							pickStylesFromTheme,
							resetUndo: resetUndoForPageCreation,
						}, {
							root: true,
						});
					} else {
						dispatch('addEcommerceProductPages', {
							pickStylesFromTheme,
							resetUndo: resetUndoForPageCreation,
						}, {
							root: true,
						});
					}
				}
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while getting store products.',
				}, {
					root: true,
				});

				captureException(error);
			} finally {
				commit('setIsLoadingProducts', {
					blockId,
					isLoading: false,
				});
			}
		},
		updateStoreProduct: ({
			state,
			commit,
		}, updatedProducts) => {
			const mergedProducts = state.products.map((product) => {
				const updatedProduct = updatedProducts.find((_updatedProduct) => _updatedProduct.id === product.id);

				if (updatedProduct) {
					return {
						...product,
						...updatedProduct,
					};
				}

				return product;
			});

			commit('setStoreProducts', {
				products: mergedProducts,
				count: state.count,
			});
		},

		setAiBuilderProducts: async ({
			commit,
			rootState,
		}, {
			images = [],
			productList = [],
		} = {}) => {
			const getImageUrl = (index) => getImageSrc(images[index].origin, images[index].path, rootState.websiteId);

			const products = Array.from({
				length: 6,
			}, (_, index) => ({
				id: generateRandomId(),
				images: [
					{
						url: getImageUrl(index),
					},
				],
				thumbnail: getImageUrl(index),
				title: productList[index].title,
				subtitle: productList[index].subtitle,
				description: productList[index].description,
				type: {
					value: 'physical',
				},
				variants: [
					{
						title: productList[index].title,
						prices: [
							{
								amount: Math.floor(
									Math.random() * (ECOMMERCE_PRICE_MAX_AMOUNT - ECOMMERCE_PRICE_MIN_AMOUNT),
								) + ECOMMERCE_PRICE_MIN_AMOUNT,
								currency: {
									code: 'usd',
									decimal_digits: 2,
									template: '$$1',
								},
							},
						],
						options: [],
					},
				],
				options: [],
			}));

			commit('setStoreProducts', {
				products,
			});
		},
		getVariantsQuantity: async ({
			commit,
			rootGetters,
			dispatch,
		}) => {
			const storeId = getStoreId(rootGetters.siteMeta);

			if (!storeId) {
				return;
			}

			try {
				const variantsQuantity = await getVariantsQuantity(storeId);

				commit('setVariantsQuantity', variantsQuantity);
			} catch (error) {
				let sentryText = 'Fetching variants quantity';

				if (error.code === 'ECONNABORTED') {
					sentryText = 'Fetching variants quantity: timeout';
				}

				captureException({
					...error,
					message: sentryText,
				});
				dispatch('notifications/notify', {
					message: 'Error while getting products quantity',
				}, {
					root: true,
				});
			}
		},
		getCategories: async ({
			commit,
			dispatch,
			rootGetters,
		}) => {
			const storeId = getStoreId(rootGetters.siteMeta);

			if (!storeId) {
				return;
			}

			try {
				commit('setIsLoadingCategories', {
					isLoading: true,
				});
				const categories = await getCategories(storeId);
				const sortedCategories = categories.sort((a, b) => a.title.localeCompare(b.title));

				commit('setCategories', sortedCategories);
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while getting categories.',
				}, {
					root: true,
				});

				captureException(error);
			} finally {
				commit('setIsLoadingCategories', {
					isLoading: false,
				});
			}
		},
		deleteEcommerceItemsFromSite: ({
			dispatch,
			rootGetters,
		}) => {
			const siteStore = useSiteStore();
			let siteDataClone = patcher.clone(siteStore.site);

			// firstly, deselect any selected block so the deletion would be smooth
			dispatch('updateCurrentBlockId', null, {
				root: true,
			});

			// then delete all the product pages together with their product blocks from default lang
			const productPageKeys = Object.keys(rootGetters.ecommerceProductPages);

			if (productPageKeys.length) {
				productPageKeys.forEach((pageId) => {
					siteDataClone = removePage({
						siteData: siteDataClone,
						pageId,
						locale: rootGetters.defaultLocale,
					});
				});
			}

			// get ecommerce block and element info from the remaining pages
			Object.entries(siteDataClone.languages).forEach(([
				locale,
				{
					blocks,
					elements,
				},
			]) => {
				const languageEcommerceBlockIds = Object.keys(blocks).filter(
					(key) => BLOCKS_ECOMMERCE.includes(blocks[key]?.type),
				);
				const languageEcommerceElementIds = Object.keys(elements).filter(
					(key) => elements[key]?.type === 'GridEcommerceButton',
				);

				languageEcommerceBlockIds.forEach((blockId) => {
					siteDataClone = removeBlock({
						siteData: siteDataClone,
						blockId,
						locale,
					});
				});

				languageEcommerceElementIds.forEach((elementId) => {
					siteDataClone = removeElement({
						siteData: siteDataClone,
						elementId,
						locale,
					});
				});
			});

			delete siteDataClone.meta[META_ECOMMERCE_TYPE];

			// remove ecommerce shopping cart
			const {
				ecommerceShoppingCart,
				...restData
			} = siteDataClone;

			dispatch('overwriteWebsiteData', {
				websiteData: {
					...restData,
				},
			}, {
				root: true,
			});
			dispatch('undoRedo/resetUndoRedo', null, {
				root: true,
			});
			dispatch('saving/saveWebsite', {}, {
				root: true,
			});
		},
		initEcommerce: async ({
			state,
			dispatch,
		}, {
			shouldPickStylesFromTheme = false,
			shouldRefetch = false,
		} = {}) => {
			dispatch('getVariantsQuantity');

			if (shouldRefetch || !state.products.length) {
				// needed for addEcommerceProductPages store action
				await dispatch('getProducts', {
					pickStylesFromTheme: shouldPickStylesFromTheme,
				});
			}

			await dispatch('getSettings');
			await dispatch('getCategories');
		},
	},
};
