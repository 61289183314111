import { COOKIE_SETUP_PAYMENT_NOTIFICATION_CLOSED_AT } from '@/constants';
import {
	SitePages,
	EcommerceProduct,
} from '@hostinger/builder-schema-validator';
import { extractText } from '@/utils/extractText';
import { generatePageSlug } from '@/utils/generatePageSlug';

export const getFilteredProductsByCategoryId = (products: Array<EcommerceProduct>, categoryId: string) => products
	.filter((product) => product.product_collections.some(
		(category: any) => category.collection_id === categoryId,
	))
	.sort((a, b) => {
		const resolveOrder = (product: EcommerceProduct) => {
			const index = product.product_collections
				.findIndex((item: any) => item.collection_id === categoryId);

			return Number(product.product_collections[index].order);
		};

		return resolveOrder(a) >= resolveOrder(b) ? 1 : -1;
	});

// eslint-disable-next-line max-len
export const getSetupPaymentNotificationClosedAtCookieName = (storeId: string) => `${COOKIE_SETUP_PAYMENT_NOTIFICATION_CLOSED_AT}/${storeId}`;

export const fillSeoSettingsForProducts = ({
	products,
	sitePages,
}: {products: Array<EcommerceProduct>, sitePages:SitePages}): Array<EcommerceProduct> => {
	const existingPageSlugs: Array<string> = Object.values(sitePages)
		.flatMap((page) => (page.slug ? [page.slug] : []));
	const existingProductSlugs: Array<string> = [];

	const ecommerceProductsWithUpdatedSeo = products.map((product: EcommerceProduct) => {
		const parsedDescriptionHtml = new DOMParser().parseFromString(product.description, 'text/html');
		const description = parsedDescriptionHtml ? extractText(parsedDescriptionHtml) : '';

		const productSlug = generatePageSlug({
			initialSlug: product.title,
			existingSlugs: [
				...existingProductSlugs,
				...existingPageSlugs,
			],
			slugSuffix: '-product',
		});

		existingProductSlugs.push(productSlug);

		const updatedProduct = {
			...product,
			seo_settings: {
				...(product.thumbnail ? {
					ogImagePath: product.thumbnail,
				} : {}),
				ogImageOrigin: 'other',
				ogImageAlt: product.title,
				description,
				slug: productSlug,
				title: product.title,
			},
		};

		return updatedProduct;
	});

	return ecommerceProductsWithUpdatedSeo;
};
