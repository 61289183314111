import escapeRegExp from 'lodash.escaperegexp';
import {
	SEO_MAX_STRING_LENGTH_TITLE,
	SEO_MIN_STRING_LENGTH_TITLE,
	SEO_MIN_STRING_LENGTH_DESCRIPTION,
} from '@zyro-inc/site-modules/constants';
import {
	SitePage,
	EcommerceProduct,
	EcommerceProductSeoSettings,
} from '@hostinger/builder-schema-validator';
import { slugifyPagePath } from '@/utils/urlValidators';

export const getIsSeoTitleLengthValid = ({ seoTitle }: { seoTitle: string }) => seoTitle.length <= SEO_MAX_STRING_LENGTH_TITLE
	&& seoTitle.length >= SEO_MIN_STRING_LENGTH_TITLE;

export const getIsFocusKeywordIncludedInString = ({
	focusKeyword,
	stringToCheck,
}: {
	focusKeyword: string | null;
	stringToCheck: string;
}) => {
	if (!focusKeyword) {
		return false;
	}

	const regex = new RegExp(`(^|\\W)${escapeRegExp(focusKeyword)}(\\W|$)`, 'i');

	return regex.test(stringToCheck);
};

export const getIsSeoTitleValid = ({
	seoTitle,
	focusKeyword,
}: {
	seoTitle: string;
	focusKeyword: string | null;
}) => seoTitle.length
	&& getIsSeoTitleLengthValid({
		seoTitle,
	})
	&& getIsFocusKeywordIncludedInString({
		focusKeyword,
		stringToCheck: seoTitle,
	});

export const getIsSeoDescriptionLengthValid = ({ seoDescription }
	: { seoDescription: string }) => seoDescription.length >= SEO_MIN_STRING_LENGTH_DESCRIPTION;

export const getIsSeoDescriptionValid = ({
	seoDescription,
	focusKeyword,
}: {
	seoDescription: string;
	focusKeyword: string | null;
}) => seoDescription.length
	&& getIsSeoDescriptionLengthValid({
		seoDescription,
	})
	&& getIsFocusKeywordIncludedInString({
		focusKeyword,
		stringToCheck: seoDescription,
	});

export const getFocusKeywordIncludedInPageSlug = ({
	focusKeyword,
	pageSlug,
}: {
	focusKeyword: string | null;
	pageSlug: string | undefined;
}) => {
	if (!focusKeyword || !pageSlug) {
		return false;
	}

	return pageSlug.toLowerCase().includes(slugifyPagePath(focusKeyword.toLowerCase()).path);
};

export const getIsProductPageSeoValid = ({
	pageId,
	pages,
	products,
	productMetaUpdate,
}: {
	pageId: string,
	pages: Record<string, SitePage>,
	products: Array<EcommerceProduct>,
	productMetaUpdate: Record<string, EcommerceProductSeoSettings>
}): boolean => {
	const page = pages[pageId];
	const pageProduct = products.find((product) => product.id === page.productId);

	if (!pageProduct) {
		return false;
	}

	const productMetaUpdates = pageProduct && productMetaUpdate[pageProduct.id];

	if (!pageProduct?.seo_settings && !productMetaUpdates) {
		return false;
	}

	const {
		slug: pageSlug,
		title: seoTitle,
		description: seoDescription,
		focusKeyword,
	} = pageProduct.seo_settings;

	const {
		slug: pageSlugUpdate,
		title: seoTitleUpdate,
		description: seoDescriptionUpdate,
		focusKeyword: focusKeywordUpdate,
	} = productMetaUpdates || {};

	const isSeoDescriptionValid = !!getIsSeoDescriptionValid({
		seoDescription: seoDescriptionUpdate || seoDescription || '',
		focusKeyword: focusKeywordUpdate || focusKeyword || null,
	});

	const isSeoTitleValid = !!getIsSeoTitleValid({
		seoTitle: seoTitleUpdate || seoTitle || '',
		focusKeyword: focusKeywordUpdate || focusKeyword || null,
	});
	const isPageSlugValid = getFocusKeywordIncludedInPageSlug({
		pageSlug: pageSlugUpdate || pageSlug || '',
		focusKeyword: focusKeywordUpdate || focusKeyword || null,
	});

	return isSeoDescriptionValid && isSeoTitleValid && isPageSlugValid;
};
