import { useStore } from 'vuex';
import { computed } from 'vue';

import { parseTagsCountFromHTML } from '@/utils/rehypeUtils';
import {
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	ELEMENT_TYPE_IMAGE,
} from '@zyro-inc/site-modules/constants';
import { useSeoStore } from '@/stores/seoStore';
import { SEO_DRAWER_TAB_ECOMMERCE } from '@/constants';

import {
	getIsSeoTitleValid,
	getIsSeoDescriptionValid,
	getFocusKeywordIncludedInPageSlug,
	getIsProductPageSeoValid,
} from '@/utils/seoValidation';

import { extractText } from '@/utils/extractText';

export const useSeo = () => {
	const {
		getters,
		state,
	} = useStore();
	const seoStore = useSeoStore();

	const sitePages = computed(() => getters.sitePages);
	const homePageId = computed(() => getters.homePageId);
	const siteBlocks = computed(() => getters.siteBlocks);
	const siteElements = computed(() => getters.siteElements);
	const products = computed(() => getters['ecommerce/products']);
	const hiddenProducts = computed(() => getters['ecommerce/hiddenProducts']);

	const getPageBlocks = (pageId) => sitePages.value[pageId]?.blocks || [];

	const getPageBlockData = (pageId) => {
		const pageBlocks = getPageBlocks(pageId);

		return Object.entries(siteBlocks.value)
			.filter(([blockId]) => pageBlocks.includes(blockId))
			.sort(([blockIdA], [blockIdB]) => {
				const indexA = pageBlocks.indexOf(blockIdA);
				const indexB = pageBlocks.indexOf(blockIdB);

				return indexA - indexB;
			})
			.reduce((acc, [blockId, blockData]) => {
				acc[blockId] = blockData;

				return acc;
			}, {});
	};

	const getPageElements = (pageId, pageBlockData) => {
		const pageElementIds = Object.values(pageBlockData).flatMap(
			(blockData) => blockData.components || [],
		);

		return Object.fromEntries(
			Object.entries(siteElements.value).filter(([elementId]) => pageElementIds.includes(elementId)),
		);
	};

	const getPureTextContentFromPageElements = (pageId, pageBlockData) => {
		const pageElements = getPageElements(pageId, pageBlockData);
		const domParser = new DOMParser();

		return Object.entries(pageElements)
			.map(([_, elementData]) => {
				if (elementData.content) {
					const parsedHtml = domParser.parseFromString(
						elementData.content,
						'text/html',
					);

					return parsedHtml ? extractText(parsedHtml) : '';
				}

				return '';
			})
			.filter(
				(content, index, array) => content && array.indexOf(content) === index,
			);
	};

	const getTextContentFromPageElements = (pageId, pageBlockData) => {
		const pageElements = getPageElements(pageId, pageBlockData);

		return Object.entries(pageElements)
			.map(([_, elementData]) => elementData.content || '')
			.filter(
				(content, index, array) => content && array.indexOf(content) === index,
			);
	};

	const getPureContentPageTextElements = (pageId) => {
		const pageBlockData = getPageBlockData(pageId);

		return {
			id: pageId,
			content: getPureTextContentFromPageElements(pageId, pageBlockData),
		};
	};

	const getPageTextElements = (pageId) => {
		const pageBlockData = getPageBlockData(pageId);

		return {
			id: pageId,
			content: getTextContentFromPageElements(pageId, pageBlockData),
		};
	};

	const currentPageTextElementContent = computed(() => {
		const pageId = state.currentPageId;

		return getPageTextElements(pageId).content;
	});

	const getPageSeoTitle = (pageId, pagesToCheck) => pagesToCheck[pageId]?.meta?.title || '';

	const getPageSeoDescription = (pageId, pagesToCheck) => pagesToCheck[pageId]?.meta?.description || '';

	const getPageSeoFocusKeyword = (pageId, pagesToCheck) => pagesToCheck[pageId]?.meta?.focusKeyword;

	const getPageSlug = (pageId, pagesToCheck) => pagesToCheck[pageId]?.slug || '';

	const getWordCountFromPage = (pageId) => {
		const { content } = getPureContentPageTextElements(pageId);

		if (content.length === 0) return 0;

		return content.join(' ').split(' ').length;
	};

	const getAllImagesFromPage = (pageId) => {
		const pageBlockData = getPageBlockData(pageId);
		const pageElements = getPageElements(pageId, pageBlockData);

		return Object.entries(pageElements).filter(
			([, elementData]) => elementData.type === ELEMENT_TYPE_IMAGE,
		);
	};

	const getImagesCountWithoutAlt = (pageId) => getAllImagesFromPage(pageId).filter(
		([, elementData]) => !elementData.settings.alt,
	).length;

	const h1CountInPage = computed(() => parseTagsCountFromHTML({
		html: currentPageTextElementContent.value.join(''),
		tagName: 'h1',
	}));
	const isPageWithSingleH1 = computed(() => h1CountInPage.value === 1);

	const getH1CountFromPage = (pageId) => parseTagsCountFromHTML({
		html: getPageTextElements(pageId).content.join(''),
		tagName: 'h1',
	});

	const isDefaultPageSeoValid = (pageId, pagesToCheck) => {
		const isHomePage = homePageId.value === pageId;

		const seoTitle = getPageSeoTitle(pageId, pagesToCheck);
		const seoDescription = getPageSeoDescription(pageId, pagesToCheck);
		const focusKeyword = getPageSeoFocusKeyword(pageId, pagesToCheck);
		const pageSlug = getPageSlug(pageId, pagesToCheck);

		const isSeoDescriptionValid = getIsSeoDescriptionValid({
			seoDescription,
			focusKeyword,
		});

		const isSeoTitleValid = getIsSeoTitleValid({
			seoTitle,
			focusKeyword,
		});
		const isH1Valid = getH1CountFromPage(pageId) === 1;
		const isPageSlugValid = isHomePage ? true : getFocusKeywordIncludedInPageSlug({
			pageSlug,
			focusKeyword,
		});

		const commonChecksPass = isSeoDescriptionValid && isSeoTitleValid && isPageSlugValid;

		const pageType = seoStore.currentTab.id === SEO_DRAWER_TAB_ECOMMERCE
			? seoStore.ecommercePages[pageId]?.type
			: sitePages.value[pageId]?.type;

		if (pageType === PAGE_TYPE_ECOMMERCE_PRODUCT) {
			return commonChecksPass;
		}

		return isH1Valid && commonChecksPass;
	};

	const isPageSeoValid = (pageId) => {
		const pageType = sitePages.value[pageId]?.type;
		const isEcommerceProduct = pageType === PAGE_TYPE_ECOMMERCE_PRODUCT;
		const isPageValid = isEcommerceProduct
			? getIsProductPageSeoValid({
				pageId,
				products: [
					...products.value,
					...hiddenProducts.value,
				],
				pages: sitePages.value,
				productMetaUpdate: state.ecommerce.productMetaUpdates,
			})
			: isDefaultPageSeoValid(pageId, sitePages.value);

		return isPageValid;
	};

	const allPageSeoStatuses = computed(() => Object.fromEntries(
		Object.entries(sitePages.value).map(([pageId]) => [
			pageId,
			isPageSeoValid(pageId),
		]),
	));
	const currentTabSeoStatuses = computed(() => Object.fromEntries(
		Object.entries(seoStore.currentTabPages).map(([pageId]) => [
			pageId,
			isPageSeoValid(pageId),
		]),
	));

	const isHomePageSeoValid = computed(() => isPageSeoValid(homePageId.value, sitePages.value));

	return {
		isHomePageSeoValid,
		h1CountInPage,
		allPageSeoStatuses,
		currentTabSeoStatuses,
		getPageSeoDescription,
		getPageSeoTitle,
		getWordCountFromPage,
		isPageWithSingleH1,
		getImagesCountWithoutAlt,
		getAllImagesFromPage,
	};
};
