<script setup lang="ts">
import CheckerList from '@/components/global/CheckerList.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import { useStore } from 'vuex';
import { MAX_LENGTH_PAGE_SLUG } from '@zyro-inc/builder/src/constants/index';
import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';
import { slugifyPagePath } from '@/utils/urlValidators';
import {
	computed,
	ref,
	onMounted,
	watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { usePageSeoSettings } from '@/use/usePageSeoSettings';
import { getFocusKeywordIncludedInPageSlug } from '@/utils/seoValidation';
import { useSiteStore } from '@/stores/siteStore';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { createDiff } from '@/utils/jsondiffpatch';

const USER_SITE_PAGE_SLUG_ECOMMERCE_CHECKOUT = 'checkout';
const {
	state,
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();
const {
	pageMetaFocusKeyword,
	pageSlug,
	mergePageMeta,
} = usePageSeoSettings();
const siteStore = useSiteStore();

const error = ref<string | null>(null);
const initialSlug = ref<string>(pageSlug.value || '');

const currentPageId = computed(() => state.currentPageId);
const isCurrentPageTypeEcommerceProduct = computed(() => getters.isCurrentPageTypeEcommerceProduct);
const isDemoEcommerce = computed(() => !getters.siteMeta.ecommerceStoreId);

const pageSlugMessage = computed(() => {
	const rootUrlMessage = `${t('builder.pageSettingsModal.textFields.pageUrl.message')} ${getters.siteUrl}`;

	if (siteStore.isCurrentPageTypeEcommerceProduct && !isDemoEcommerce.value) {
		return `${rootUrlMessage}/${pageSlug.value || ''}`;
	}

	const path = getPagePathFromId({
		pageId: currentPageId.value,
		siteData: siteStore.site,
		locale: state.currentLocale,
	});

	return `${rootUrlMessage}${path}`;
});

const updatedProductsSlugs = computed(() => state.ecommerce.products.map((product: EcommerceProduct) => {
	const productMeta = product.seo_settings || {};
	const metaUpdates = state.ecommerce.productMetaUpdates[product.id] || {};

	const updatedMeta = {
		...productMeta,
		metaUpdates,
	};

	return updatedMeta.slug;
}));

const updatedPagesSlugs = computed(() => {
	const pagesUpdates = createDiff(state.saving.siteDataSnapshot, siteStore.site);
	// @ts-ignore
	const updatedPagesData = pagesUpdates?.languages[state.currentLocale].pages;

	return Object.entries(siteStore.site.languages[state.currentLocale].pages).map(([pageId, pageData]) => {
		if (updatedPagesData && updatedPagesData[pageId]?.slug) {
			return updatedPagesData[pageId].slug[1];
		}

		return pageData.slug;
	});
});

const isFocusKeywordIncludeInPageSug = computed(() => getFocusKeywordIncludedInPageSlug({
	pageSlug: pageSlug.value,
	focusKeyword: pageMetaFocusKeyword.value,
}));

const pageSeoCheckerList = computed(() => ([
	{
		id: 'seoPageFocusKeyword',
		isCompleted: isFocusKeywordIncludeInPageSug.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerPageSlugIncludeFocusKeyword',
			value: pageMetaFocusKeyword.value ? `"${pageMetaFocusKeyword.value}"` : '',
		},
	},
]));
const setCheckoutSlugError = () => {
	error.value = t('builder.ecommerceCheckoutSlugError');
};

const setEmptySlugError = () => {
	error.value = t('validate.emptyValue');
};

const setDuplicatedSlugError = () => {
	error.value = t('builder.ecommerceDuplicatedSlugError');
};

const slugExists = (slug: string) => {
	if (pageSlug.value === slug || initialSlug.value === slug) {
		return false;
	}

	const slugs = [
		...updatedPagesSlugs.value,
		...updatedProductsSlugs.value,
	];

	return slugs.includes(slug);
};

const updatePageSlug = (newValue: string) => {
	const slug = slugifyPagePath(newValue).path;

	if (!slug) {
		setEmptySlugError();
	} else if (slug === USER_SITE_PAGE_SLUG_ECOMMERCE_CHECKOUT) {
		setCheckoutSlugError();
	} else if (slugExists(slug)) {
		setDuplicatedSlugError();
	} else {
		error.value = null;
	}

	if (isCurrentPageTypeEcommerceProduct.value && !isDemoEcommerce.value) {
		mergePageMeta({
			slug,
		});

		return;
	}

	dispatch('mergePageData', {
		pageId: currentPageId.value,
		pageData: {
			slug,
		},
	});
};

const handleBlur = () => {
	if (initialSlug.value === pageSlug.value) {
		return;
	}

	if (!error.value) {
		initialSlug.value = pageSlug.value;
	}

	if (isCurrentPageTypeEcommerceProduct.value && !isDemoEcommerce.value) {
		mergePageMeta({
			slug: initialSlug.value,
		});

		return;
	}

	dispatch('mergePageData', {
		pageId: currentPageId.value,
		pageData: {
			slug: initialSlug.value,
		},
	});
};

onMounted(() => {
	if (state.gui.activeDrawerSettings?.isCheckoutSlugError) {
		setCheckoutSlugError();
		dispatch('gui/setActiveDrawerSettings', {});
	}
});

watch(currentPageId, () => {
	initialSlug.value = pageSlug.value;
});

</script>
<template>
	<div>
		<ZyroFieldInput
			id="page-url"
			:model-value="pageSlug"
			:label="t('builder.pageSettingsModal.textFields.pageUrl.label')"
			:message="pageSlugMessage"
			:maxlength="MAX_LENGTH_PAGE_SLUG"
			:error="error"
			@update:model-value="updatePageSlug"
			@input-blur="handleBlur"
		/>
		<CheckerList :checker-items="pageSeoCheckerList" />
	</div>
</template>

<style lang="scss" scoped>
.status-icon {
	flex-shrink: 0;
	color: $color-warning-dark;
	margin-right: 8px;

	&--completed {
		color: $color-success;
	}
}

.collapsible-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: 400;
}

:deep(.collapsible) {
	border: 1px solid $color-gray-border;
	border-radius: 8px;
	overflow: hidden;
}

:deep(.collapsible__header) {
	padding: 12px 16px;
}

.checker-list-item {
	display: flex;
	align-items: top;
}

.icon-wrapper {
	display: flex;
}
</style>
