export const availableIcons = [
	{
		iconName: 'letterboxd',
		brandColor: '',
		identifiers: ['letterboxd.com'],
	},
	{
		iconName: 'buymeacoffee',
		brandColor: '',
		identifiers: ['buymeacoffee'],
	},
	{
		iconName: 'mastodon',
		brandColor: '',
		identifiers: [
			'mastodon',
			'joinmastodon',
			'pawoo.net',
			'mstdn',
			'counter.social',
			'mas.to',
			'gc2.jp',
			'dataplatform.social',
			'fosstodon.org',
		],
	},
	{
		iconName: 'itch-io',
		brandColor: '',
		identifiers: [
			'(.*?)itch.io',
			'itch.io',
		],
	},
	{
		iconName: 'youtube-music',
		brandColor: '',
		identifiers: ['music.youtube'],
	},
	{
		iconName: 'vrbo',
		brandColor: '',
		identifiers: ['vrbo'],
	},
	{
		iconName: 'flipboard',
		brandColor: '',
		identifiers: [
			'flipboard',
			'about.flipboard',
			'flip.it',
		],
	},
	{
		iconName: 'airbnb',
		brandColor: '#fd5c63',
		identifiers: ['airbnb'],
	},
	{
		iconName: 'amazon',
		brandColor: '#ff9900',
		identifiers: ['amazon'],
	},
	{
		iconName: 'amazon-pay',
		brandColor: '#ff9900',
		identifiers: [
			'amazon-pay',
			'pay.amazon',
		],
	},
	{
		iconName: 'bandcamp',
		brandColor: '',
		identifiers: [
			'(.*?)bandcamp',
			'bandcamp',
		],
	},
	{
		iconName: 'reddit',
		brandColor: '#ff9900',
		identifiers: [
			'reddit',
			'redd.it',
		],
	},
	{
		iconName: 'app-store',
		brandColor: '',
		identifiers: ['app-store'],
	},
	{
		iconName: 'app-store-ios',
		brandColor: '',
		identifiers: ['app-store-ios'],
	},
	{
		iconName: 'apple',
		brandColor: '#000000',
		identifiers: ['apple'],
	},
	{
		iconName: 'apple-podcasts',
		brandColor: '#000000',
		identifiers: ['podcasts.apple'],
	},
	{
		iconName: 'apple-pay',
		brandColor: '',
		identifiers: [
			'apple-pay',
			'pay.apple',
			'payments.apple',
		],
	},
	{
		iconName: 'audible',
		brandColor: '',
		identifiers: ['audible'],
	},
	{
		iconName: 'behance',
		brandColor: '#1769ff',
		identifiers: ['behance'],
	},
	{
		iconName: 'bitbucket',
		brandColor: '#205081',
		identifiers: ['bitbucket'],
	},
	{
		iconName: 'bitcoin',
		brandColor: '',
		identifiers: ['bitcoin'],
	},
	{
		iconName: 'btc',
		brandColor: '',
		identifiers: ['btc'],
	},
	{
		iconName: 'codepen',
		brandColor: '#000000',
		identifiers: ['codepen'],
	},
	{
		iconName: 'discord',
		brandColor: '#7289da',
		identifiers: ['discord'],
	},
	{
		iconName: 'dribbble',
		brandColor: '#ea4c89',
		identifiers: ['dribbble'],
	},
	{
		iconName: 'dropbox',
		brandColor: '#007ee5',
		identifiers: ['dropbox'],
	},
	{
		iconName: 'ebay',
		brandColor: '',
		identifiers: ['ebay'],
	},
	{
		iconName: 'etsy',
		brandColor: '#d5641c',
		identifiers: ['etsy'],
	},
	{
		iconName: 'facebook',
		brandColor: '#4267B2',
		identifiers: ['(.*?)facebook'],
	},
	{
		iconName: 'facebook-f',
		brandColor: '#4267B2',
		identifiers: ['facebook-f'],
	},
	{
		iconName: 'facebook-messenger',
		brandColor: '#4267B2',
		identifiers: [
			'facebook-messenger',
			'm.me',
			'messenger',
		],
	},
	{
		iconName: 'facebook-square',
		brandColor: '#4267B2',
		identifiers: ['facebook-square'],
	},
	{
		iconName: 'github',
		brandColor: '',
		identifiers: ['github'],
	},
	{
		iconName: 'goodreads-g',
		brandColor: '#553b08',
		identifiers: [
			'goodreads-g',
			'goodreads',
		],
	},
	{
		iconName: 'google-maps',
		brandColor: '',
		identifiers: [
			'maps.google',
			'goo.gl/maps',
			'google.(.*?)/maps',
		],
	},
	{
		iconName: 'google',
		brandColor: '',
		identifiers: ['google'],
	},
	{
		iconName: 'google-play',
		brandColor: '',
		identifiers: ['google-play'],
	},
	{
		iconName: 'instagram',
		brandColor: '',
		identifiers: ['instagram'],
	},
	{
		iconName: 'itunes',
		brandColor: '',
		identifiers: [
			'itunes',
			'music.apple',
		],
	},
	{
		iconName: 'itunes-note',
		brandColor: '',
		identifiers: ['itunes-note'],
	},
	{
		iconName: 'lastfm',
		brandColor: '#d51007',
		identifiers: ['lastfm'],
	},
	{
		iconName: 'linkedin-in',
		brandColor: '',
		identifiers: [
			'linkedin',
			'linkedin.com',
			'linked.in',
			'in.linkedin',
			'linkedin.com',
		],
	},
	{
		iconName: 'medium-m',
		brandColor: '',
		identifiers: ['medium'],
	},
	{
		iconName: 'caffeine',
		brandColor: '',
		identifiers: [
			'caffeine.tv',
			'caffeine',
		],
	},
	{
		iconName: 'flickr',
		brandColor: '',
		identifiers: [
			'flickr',
			'flic.kr',
		],
	},
	{
		iconName: 'gab',
		brandColor: '',
		identifiers: ['gab'],
	},
	{
		iconName: 'quora',
		brandColor: '',
		identifiers: [
			'quora',
			'qr.ae',
		],
	},
	{
		iconName: 'steam',
		brandColor: '',
		identifiers: [
			'steam',
			'steamcommunity',
			'store.steampowered',
		],
	},
	{
		iconName: 'tribe',
		brandColor: '',
		identifiers: [
			'tribe.so',
			'tribe',
		],
	},
	{
		iconName: 'mixcloud',
		brandColor: '#273a4b',
		identifiers: ['mixcloud'],
	},
	{
		iconName: 'paypal',
		brandColor: '',
		identifiers: ['paypal'],
	},
	{
		iconName: 'pinterest-p',
		brandColor: '#bd081c',
		identifiers: [
			'pinterest',
			'pinterest-p',
			'pin',
			'pin.it',
		],
	},
	{
		iconName: 'pinterest',
		brandColor: '#bd081c',
		identifiers: [
			'pinterest-p',
			'pinterest',
		],
	},
	{
		iconName: 'skype',
		brandColor: '#00aff0',
		identifiers: [
			'skype',
			'join.skype',
		],
	},
	{
		iconName: 'slack',
		brandColor: '',
		identifiers: ['slack'],
	},
	{
		iconName: 'snapchat-ghost',
		brandColor: '#fffc00',
		identifiers: [
			'snapchat-ghost',
			'snapchat',
		],
	},
	{
		iconName: 'soundcloud',
		brandColor: '#ff8800',
		identifiers: ['soundcloud'],
	},
	{
		iconName: 'viber',
		brandColor: '#ff8800',
		identifiers: ['viber'],
	},
	{
		iconName: 'spotify',
		brandColor: '#1db954',
		identifiers: [
			'spotify',
			'open.spotify',
		],
	},
	{
		iconName: 'telegram',
		brandColor: '#0088cc',
		identifiers: [
			'telegram',
			't.me',
		],
	},
	{
		iconName: 'telegram-plane',
		brandColor: '',
		identifiers: ['telegram-plane'],
	},
	{
		iconName: 'tiktok',
		brandColor: '',
		identifiers: [
			'tiktok',
			'vm.tiktok',
		],
	},
	{
		iconName: 'threads',
		brandColor: '',
		identifiers: ['threads'],
	},
	{
		iconName: 'tripadvisor',
		brandColor: '#00af87',
		identifiers: ['tripadvisor'],
	},
	{
		iconName: 'tumblr',
		brandColor: '#35465c',
		identifiers: ['tumblr'],
	},
	{
		iconName: 'twitch',
		brandColor: '#6441a5',
		identifiers: ['twitch'],
	},
	{
		iconName: 'twitter',
		brandColor: '#1da1f2',
		identifiers: [
			'twitter',
			'^x',
		],
	},
	{
		iconName: 'amazon-music',
		brandColor: '',
		identifiers: ['music.amazon'],
	},
	{
		iconName: 'mewe',
		brandColor: '',
		identifiers: ['mewe'],
	},
	{
		iconName: 'reverbnation',
		brandColor: '',
		identifiers: ['reverbnation'],
	},
	{
		iconName: 'rumble',
		brandColor: '',
		identifiers: ['rumble'],
	},
	{
		iconName: 'deezer',
		brandColor: '',
		identifiers: ['deezer'],
	},
	{
		iconName: 'dailymotion',
		brandColor: '',
		identifiers: ['dailymotion'],
	},
	{
		iconName: 'eventbrite',
		brandColor: '',
		identifiers: ['eventbrite'],
	},
	{
		iconName: 'gumroad',
		brandColor: '',
		identifiers: ['gumroad'],
	},
	{
		iconName: 'reading',
		brandColor: '',
		identifiers: ['reading'],
	},
	{
		iconName: 'artstation',
		brandColor: '',
		identifiers: [
			'.*\\.artstation',
			'artstation',
		],
	},
	{
		iconName: 'shazam',
		brandColor: '',
		identifiers: ['shazam'],
	},
	{
		iconName: 'buffer',
		brandColor: '',
		identifiers: ['buffer'],
	},
	{
		iconName: 'digg',
		brandColor: '',
		identifiers: ['digg'],
	},
	{
		iconName: 'stumbleupon',
		brandColor: '',
		identifiers: ['stumbleupon'],
	},
	{
		iconName: 'patreon',
		brandColor: '',
		identifiers: ['patreon'],
	},
	{
		iconName: 'booksy',
		brandColor: '',
		identifiers: ['booksy'],
	},
	{
		iconName: 'google-business',
		brandColor: '',
		identifiers: ['business.google'],
	},
	{
		iconName: 'google-scholar',
		brandColor: '',
		identifiers: ['scholar.google'],
	},
	{
		iconName: 'imdb',
		brandColor: '',
		identifiers: ['imdb'],
	},
	{
		iconName: 'researchgate',
		brandColor: '',
		identifiers: ['researchgate'],
	},
	{
		iconName: 'vimeo-v',
		brandColor: '#1ab7ea',
		identifiers: [
			'vimeo-v',
			'vimeo',
		],
	},
	{
		iconName: 'vk',
		brandColor: '#45668e',
		identifiers: ['vk'],
	},
	{
		iconName: 'whatsapp',
		brandColor: '#25d366',
		identifiers: [
			'whatsapp',
			'wa.me',
			'api.whatsapp',
			'chat.whatsapp',
		],
	},
	{
		iconName: 'youtube',
		brandColor: '#ff0000',
		identifiers: [
			'youtube',
			'youtu.be',
		],
	},
	{
		iconName: 'lunch-club',
		brandColor: '',
		identifiers: ['lunchclub'],
	},
	{
		iconName: 'beatport',
		brandColor: '',
		identifiers: ['beatport'],
	},
	{
		iconName: '1x',
		brandColor: '',
		identifiers: ['1x'],
	},
	{
		iconName: 'ello',
		brandColor: '',
		identifiers: ['ello'],
	},
	{
		iconName: 'crunchbase',
		brandColor: '',
		identifiers: ['crunchbase'],
	},
	{
		iconName: 'tidal',
		identifiers: [
			'tidal',
			'listen.tidal',
		],
	},
	{
		iconName: 'mail',
		identifiers: ['mailto:'],
	},
	{
		iconName: 'tel',
		identifiers: ['tel:'],
	},
	{
		iconName: 'vero',
		identifiers: ['vero'],
	},
	{
		iconName: 'trading-view',
		identifiers: ['tradingview'],
	},
	{
		iconName: 'tableau',
		identifiers: ['(.*?)tableau'],
	},
	{
		iconName: 'truth-social',
		identifiers: ['truthsocial'],
	},
	{
		iconName: 'upwork',
		identifiers: ['upwork'],
	},
	{
		iconName: 'notion',
		brandColor: '',
		identifiers: [
			'.*\\.notion',
			'notion',
		],
	},
	{
		iconName: 'pandora',
		brandColor: '',
		identifiers: [
			'pandora.app.link',
			'pandora',
		],
	},
	{
		iconName: 'line',
		brandColor: '',
		identifiers: ['line.me'],
	},
	{
		iconName: 'deviantart',
		brandColor: '',
		identifiers: ['deviantart'],
	},
	{
		iconName: 'yelp',
		brandColor: '',
		identifiers: ['yelp.com'],
	},
	{
		iconName: 'walmart',
		brandColor: '',
		identifiers: ['walmart.com'],
	},
	{
		iconName: 'udemy',
		brandColor: '',
		identifiers: ['udemy.com'],
	},
	{
		iconName: 'binance',
		brandColor: '',
		identifiers: ['binance.com'],
	},
	{
		iconName: 'bingx',
		brandColor: '',
		identifiers: ['bingx.com'],
	},
	{
		iconName: 'bybit',
		brandColor: '',
		identifiers: ['bybit.com'],
	},
	{
		iconName: 'cash-app',
		brandColor: '',
		identifiers: ['cash.app'],
	},
	{
		iconName: 'kucoin',
		brandColor: '',
		identifiers: ['kucoin.com'],
	},
	{
		iconName: 'opensea',
		brandColor: '',
		identifiers: ['opensea.io'],
	},
	{
		iconName: 'venmo',
		brandColor: '',
		identifiers: ['venmo.com'],
	},
	{
		iconName: 'xing',
		brandColor: '',
		identifiers: ['xing.com'],
	},
	{
		iconName: 'shopee',
		brandColor: '',
		identifiers: ['shopee'],
	},
	{
		iconName: 'tokopedia',
		brandColor: '',
		identifiers: ['tokopedia'],
	},
	{
		iconName: 'linktree',
		identifiers: ['linktr.ee'],
	},
	{
		iconName: 'audius',
		identifiers: ['audius.co'],
	},
	{
		iconName: 'anghami',
		identifiers: ['play.anghami'],
	},
	{
		iconName: 'wechat',
		identifiers: ['wechat.com'],
	},
	{
		iconName: 'bluesky',
		identifiers: ['bsky.app'],
	},
	{
		iconName: 'fiverr',
		identifiers: ['fiverr'],
	},
	{
		iconName: 'coingecko',
		identifiers: ['coingecko'],
	},
	{
		iconName: 'dexscreener',
		identifiers: ['dexscreener'],
	},
	{
		iconName: 'dextools',
		identifiers: ['dextools'],
	},
	{
		iconName: 'etherscan',
		identifiers: ['etherscan'],
	},
];
