<template>
	<div
		class="datalist"
		:class="[{ 'datalist--opened': isOpened }, { 'datalist--error': error }]"
	>
		<VueSelect
			v-qa="`dropdown-button-select-${modelValue?.[labelKey] ?? ''}`"
			:input-id="inputId"
			class="datalist__select"
			:class="[{ 'datalist__select--disabled': disabled }]"
			:options="options"
			:model-value="modelValue"
			:placeholder="placeholder"
			:label="labelKey"
			:filterable="filterable"
			:taggable="taggable"
			:create-option="createOption"
			:dropdown-should-open="shouldDropdownOpen"
			:selectable="selectable"
			:searchable="searchable"
			:disabled="disabled"
			@update:model-value="$emit('update:model-value', $event)"
			@open="isOpened = true"
			@close="isOpened = false"
			@search="$emit('search', $event)"
			@search:blur="$emit('blur', $event)"
		>
			<template #open-indicator>
				<ZyroSvgDeprecated
					v-show="!shouldOpenDropdownOnInput"
					class="datalist__select-arrow"
					name="chevron"
					direction="down"
				/>
			</template>

			<template #no-options>
				<div class="datalist__no-results text-body-2">
					{{ $t('common.searchNoResults') }}
				</div>
			</template>

			<template #option="option">
				<slot
					name="option"
					:option="option"
				/>
			</template>

			<template #selected-option="option">
				<slot
					name="selected-option"
					:option="option"
				/>
			</template>
		</VueSelect>
	</div>
</template>

<script>
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';

import VueSelect from 'vue-select';

import {
	defineComponent,
	ref,
} from 'vue';

export default defineComponent({
	components: {
		ZyroSvgDeprecated,
		VueSelect,
	},

	props: {
		options: {
			type: Array,
			default: () => [],
		},
		inputId: {
			type: String,
			default: null,
		},
		labelKey: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: '',
		},
		modelValue: {
			type: [
				Object,
				String,
				Number,
			],
			default: null,
		},
		taggable: {
			type: Boolean,
			default: false,
		},
		createOption: {
			type: Function,
			default(newOption) {
				if (typeof this.optionList[0] === 'object') {
					// eslint-disable-next-line no-param-reassign
					newOption = {
						[this.label]: newOption,
					};
				}

				// eslint-disable-next-line vue/custom-event-name-casing
				this.$emit('option:created', newOption);

				return newOption;
			},
		},
		filterable: {
			type: Boolean,
			default: true,
		},
		searchable: {
			type: Boolean,
			default: true,
		},
		selectable: {
			type: Function,
			default: () => true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		shouldOpenDropdownOnInput: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: '',
		},
	},

	emits: [
		'update:model-value',
		'search',
		'option:created',
		'blur',
	],

	setup(props) {
		const isOpened = ref(false);
		const shouldDropdownOpen = ({ search }) => (props.shouldOpenDropdownOnInput ? !!search.length : isOpened.value);

		return {
			shouldDropdownOpen,
			isOpened,
		};
	},
});
</script>

<style lang="scss" scoped>
.datalist {
	$this: &;

	height: 48px;
	border-radius: 8px;
	background-color: $color-gray-light;

	&--opened {
		#{$this}__select-arrow {
			transform: rotateZ(360deg);
		}
	}

	&--error {
		border-color: $color-danger;
	}

	&__select {
		height: 100%;

		&--disabled {
			opacity: 0.5;
		}
	}

	&__select-arrow {
		transition: transform 0.2s;
	}

	&__no-results {
		padding: 8px;
	}

	// Styles that overrides vue-select internal styles
	:deep() {
		// disable webkit added elements
		input::-webkit-search-decoration {
			display: none;
		}

		.v-select {
			position: relative;
			box-sizing: border-box;
			font-family: inherit;

			& * {
				box-sizing: border-box;
			}
		}

		$this: ".vs";

		// .vs--single.vs--open
		#{$this}--single#{$this}--open {
			// .vs--single.vs--open .vs__selected
			& #{$this}__selected {
				position: absolute;
				opacity: 0.4;
			}

			// .vs--single.vs--open.vs--searching .vs__selected
			&#{$this}--searching {
				& #{$this}__selected {
					opacity: 0;
				}
			}
		}

		.vs {
			&__clear {
				display: none;
			}

			&__dropdown-toggle {
				display: flex;
				height: 100%;

				// padding: 0 10px 4px;
			}

			&__selected {
				display: flex;
				align-items: center;
				height: 100%;
				padding: 12px 16px;
				font-size: 14px;
				line-height: 24px;
			}

			&__search,
			&__search:focus {
				z-index: 1;
				flex-grow: 1;
				width: 0;
				max-width: 100%;
				padding: 12px 16px;
				line-height: 24px;
				font-size: 14px;
				background: transparent;
				border: none;
				outline: none;

				&::-webkit-search-cancel-button {
					appearance: none;
				}
			}

			&__actions {
				display: flex;
				align-items: center;
				padding: 4px 16px 0 3px;
			}

			&__selected-options {
				position: relative;
				display: flex;
				flex-basis: 100%;
				flex-grow: 1;
				flex-wrap: wrap;
			}

			&__dropdown-menu {
				position: absolute;
				z-index: 2;
				width: 100%;
				min-width: 160px;
				max-height: 350px;
				padding: 8px 0;
				border-radius: 8px;
				overflow-y: auto;
				list-style: none;
				background-color: white;
				box-shadow: 0 0 12px 0 rgba(29, 30, 32, 16%);
			}

			&__dropdown-option {
				padding: 8px 16px;
				font-size: 14px;
				line-height: 24px;
				cursor: pointer;

				&--highlight {
					color: $color-dark;
					background-color: $color-gray-light;
					transition: background-color 0.1s;
				}
			}
		}
	}
}
</style>
