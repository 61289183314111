import { useSiteGetters } from '@/use/useSiteGetters';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import {
	SiteBlock,
	SiteBuilderCompletedSteps,
	SiteData,
	SiteElement,
	SiteElements,
	SiteLanguages,
	SitePage,
	SiteMeta,
	SiteStyles,
	// siteSchemaValidator,
	SiteLanguage,
	SiteFont,
} from '@hostinger/builder-schema-validator';
import { defineStore } from 'pinia';
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import { PINIA_STORES } from '@/constants/stores';

export const useSiteStore = defineStore(PINIA_STORES.SITE, () => {
	const { dispatch } = useStore();

	const schemaValidationError = (error: any) => {
		// eslint-disable-next-line no-console
		console.log(error);
		dispatch('notifications/notify', {
			message: 'Unexpected error occurred. Try reloading the page.',
		});
	};

	const site = ref<SiteData | Record<string, never>>({});
	const addElementData = ref<SiteElement | Record<string, never>>({});

	const websiteId = ref('');
	const currentPageId = ref('');
	const currentBlockId = ref('');
	const currentElementId = ref('');

	const {
		currentLocale,
		siteLanguages,
		siteLanguagesList,
		hasLanguages,
		currentSiteLanguage,
		siteBlocks,
		siteElements,
		sitePages,
		siteNav,
		isNavHidden,
		blogReadingTimeText,
		homePageId,
		hResourceId,
		ecommerceProductPages,
		defaultLocale,
		ecommerceShoppingCart,
		siteForms,
		siteStyles,
		siteTemplate,
		hasBlankTemplate,
		hasGeneratedTemplate,
		hasImportedTemplate,
		templateType,
		siteHomePageTitle,
		siteFonts,
		currentPage,
		currentBlock,
		currentBlockType,
		currentBlockSettings,
		currentBlockStyles,
		currentBlockSlot,
		currentElement,
		currentElementContent,
		currentElementSettings,
		currentElementStyles,
		currentElementType,
		currentElementBlockId,
		currentElementBlock,
		currentElementBlockType,
		getElementBLockId,
		headerBlock,
		footerBlock,
		doesFooterExist,
		doesPageIdAlreadyExist,
		isCurrentPageTypeBlog,
		isCurrentPageEmpty,
		isPageSlugUnique,
		blogCategories,
		isLanguageSwitcherHidden,
		defaultPages,
		blogPages,
		ecommerceLocaleProductPages,
		draftBlogPages,
		scheduledBlogPages,
		publishedBlogPages,
		builderCompletedSteps,
		siteMeta,
		isPrivateModeActive,
		aiSalesAssistant,
		isCurrentPagePrivate,
		isCurrentPageTypeEcommerceProduct,
	} = useSiteGetters({
		site,
		currentPageId,
		currentBlockId,
		currentElementId,
	});

	const isCustomCodeDisabled = computed(() => siteMeta.value.isCustomCodeDisabled || false);

	const setSiteCustomCodeDisabled = (value: boolean) => {
		site.value.meta.isCustomCodeDisabled = value;
	};

	const setSiteId = (id: string) => {
		websiteId.value = id;
	};

	const setSiteCurrentPageId = (id: string) => {
		currentPageId.value = id;
	};

	const setSiteCurrentBlockId = (id: string) => {
		currentBlockId.value = id;
	};

	const setSiteCurrentElementId = (id: string) => {
		currentElementId.value = id;
	};

	const setSiteHomePageId = ({
		locale = SYSTEM_LOCALE,
		id,
	}: { locale: string, id: string }) => {
		site.value.languages[locale].homePageId = id;
	};

	const setSiteData = (data: SiteData) => {
		try {
			// siteSchemaValidator.validateSiteData(data);

			site.value = data;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteLanguagesData = (data: SiteLanguages) => {
		try {
			// siteSchemaValidator.validateSiteLanguages(data);

			site.value.languages = data;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteLanguageData = ({
		locale = SYSTEM_LOCALE,
		data,
	}: { locale: string, data: SiteLanguage}) => {
		try {
			// siteSchemaValidator.validateSiteLanguage(data);

			site.value.languages[locale] = data;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSitePageData = ({
		locale = SYSTEM_LOCALE,
		pageId,
		data,
	}: { locale: string, pageId: string, data: SitePage }) => {
		try {
			// siteSchemaValidator.validateSitePage(data);

			site.value.languages[locale].pages = {
				...site.value.languages[locale].pages,
				[pageId]: data,
			};
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteBlockData = ({
		locale = SYSTEM_LOCALE,
		blockId,
		data,
	}: { locale: string; blockId: string; data: SiteBlock; }) => {
		try {
			// siteSchemaValidator.validateSiteBlock(data);

			site.value.languages[locale].blocks[blockId] = data;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteAddElementData = (data: SiteElement) => {
		try {
			// siteSchemaValidator.validateSiteElement(data);

			addElementData.value = data;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteElementData = ({
		locale = SYSTEM_LOCALE,
		elementId,
		data,
	}: { locale: string, elementId: string, data: SiteElement }) => {
		try {
			// siteSchemaValidator.validateSiteElement(data);

			site.value.languages[locale].elements = {
				...site.value.languages[locale].elements,
				[elementId]: data,
			};
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteElementsData = ({
		locale = SYSTEM_LOCALE,
		data,
	}: { locale: string, data: SiteElements }) => {
		try {
			const elementLocale = isCurrentPagePrivate.value ? SYSTEM_LOCALE : locale;

			site.value.languages[elementLocale].elements = {
				...site.value.languages[elementLocale].elements,
				...data,
			};
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteMetaData = ({
		key,
		value,
	}: { key: string, value: SiteMeta[keyof SiteMeta]}) => {
		try {
			const newMeta: SiteMeta = {
				...site.value.meta,
				[key]: value,
			};

			// siteSchemaValidator.validateSiteMeta(newMeta);

			site.value.meta = newMeta;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteFontsData = (value: SiteFont[]) => {
		try {
			// siteSchemaValidator.validateSiteFonts(value);

			site.value.fonts = value;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteStyleProperties = ({
		element,
		value,
	}: { element: string, value: any }) => {
		try {
			const newStyles: SiteStyles = {
				...site.value.styles,
				[element]: {
					...(site.value.styles as any)[element],
					...value,
				},
			};

			// siteSchemaValidator.validateSiteStyles(newStyles);

			site.value.styles = newStyles;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteStyleProperty = ({
		element,
		property,
		value,
	}: { element: string, property: string, value: any }) => {
		try {
			const newStyles: SiteStyles = {
				...site.value.styles,
				[element]: {
					...(site.value.styles as any)[element],
					[property]: value,
				},
			};

			// siteSchemaValidator.validateSiteStyles(newStyles);

			site.value.styles = newStyles;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const setSiteBuilderCompletedStepsData = (steps: SiteBuilderCompletedSteps) => {
		try {
			// siteSchemaValidator.validateSiteBuilderCompletedSteps(steps);

			site.value.builderCompletedSteps = steps;
		} catch (error) {
			schemaValidationError(error);
		}
	};

	const removeNonExistingBlocksFromSite = ({
		pageId,
		nonExistingBlocks,
	}: { pageId: string, nonExistingBlocks: string[] }) => {
		const page = site.value.languages[currentLocale.value].pages[pageId];

		page.blocks = [...(page.blocks || []).filter((blockId) => !nonExistingBlocks.includes(blockId))];
	};

	return {
		websiteId,
		site,
		siteMeta,
		isPrivateModeActive,
		aiSalesAssistant,
		currentBlockId,
		currentElementId,
		currentPageId,
		addElementData,
		currentLocale,
		siteLanguages,
		siteLanguagesList,
		hasLanguages,
		currentSiteLanguage,
		siteBlocks,
		siteElements,
		sitePages,
		siteNav,
		isNavHidden,
		blogReadingTimeText,
		homePageId,
		hResourceId,
		ecommerceProductPages,
		defaultLocale,
		ecommerceShoppingCart,
		siteForms,
		siteStyles,
		siteTemplate,
		hasGeneratedTemplate,
		hasImportedTemplate,
		hasBlankTemplate,
		templateType,
		siteHomePageTitle,
		siteFonts,
		currentPage,
		currentBlock,
		currentBlockType,
		currentBlockSettings,
		currentBlockStyles,
		currentBlockSlot,
		currentElement,
		currentElementContent,
		currentElementSettings,
		currentElementStyles,
		currentElementType,
		currentElementBlockId,
		currentElementBlock,
		currentElementBlockType,
		getElementBLockId,
		headerBlock,
		footerBlock,
		doesFooterExist,
		doesPageIdAlreadyExist,
		isCurrentPageTypeBlog,
		isCurrentPageTypeEcommerceProduct,
		isCurrentPageEmpty,
		isPageSlugUnique,
		blogCategories,
		isLanguageSwitcherHidden,
		defaultPages,
		blogPages,
		ecommerceLocaleProductPages,
		draftBlogPages,
		scheduledBlogPages,
		publishedBlogPages,
		builderCompletedSteps,
		setSiteId,
		setSiteCurrentElementId,
		setSiteCurrentPageId,
		setSiteCurrentBlockId,
		setSiteData,
		setSitePageData,
		setSiteBlockData,
		setSiteElementData,
		setSiteElementsData,
		setSiteLanguagesData,
		setSiteHomePageId,
		setSiteBuilderCompletedStepsData,
		setSiteMetaData,
		setSiteFontsData,
		setSiteAddElementData,
		setSiteStyleProperties,
		setSiteStyleProperty,
		removeNonExistingBlocksFromSite,
		setSiteLanguageData,
		isCustomCodeDisabled,
		setSiteCustomCodeDisabled,
	};
});
