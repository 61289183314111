import { getBlockIdByElementId } from '@/utils/layout';
import {
	GENERATED_SITE_TEMPLATE_ID,
	IMPORTED_SITE_TEMPLATE_ID,
	PAGE_TYPE_BLOG,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	PAGE_TYPE_PRIVATE,
	PAGE_ID_PRIVATE,
	SYSTEM_LOCALE,
} from '@zyro-inc/site-modules/constants';
import {
	SiteBlock,
	SiteBlocks,
	SiteData,
	SiteEcommerceShoppingCart,
	SiteElements,
	SiteForms,
	SiteLanguage,
	SiteLanguages,
	SiteMeta,
	SiteNavItem,
	SitePage,
	SitePages,
	SiteStyles,
} from '@hostinger/builder-schema-validator';
import { filterObject } from '@zyro-inc/site-modules/utils/object';
import {
	Ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';

interface SiteGettersPayload {
	site: Ref<SiteData | Record<string, never>>;
	currentPageId: Ref<string>;
	currentBlockId: Ref<string>;
	currentElementId: Ref<string>;
}

export const useSiteGetters = ({
	site,
	currentPageId,
	currentBlockId,
	currentElementId,
}: SiteGettersPayload) => {
	const { state } = useStore();
	const siteMeta = computed<SiteMeta | Record<string, never>>(() => site.value?.meta || {});

	const currentLocale = computed(() => state.currentLocale);
	const hResourceId = computed(() => state.hResourceId);

	const siteTemplate = computed(() => siteMeta.value.template);
	const hasGeneratedTemplate = computed(() => siteTemplate.value === GENERATED_SITE_TEMPLATE_ID);
	const hasImportedTemplate = computed(() => siteTemplate.value === IMPORTED_SITE_TEMPLATE_ID);
	const hasBlankTemplate = computed(() => siteTemplate.value === 'blank');
	const templateType = computed(() => (hasGeneratedTemplate.value || hasImportedTemplate.value || hasBlankTemplate.value ? siteTemplate.value : 'template'));

	const defaultLocale = computed(() => siteMeta.value?.defaultLocale || SYSTEM_LOCALE);

	const siteLanguages = computed<SiteLanguages | Record<string, never>>(() => site.value?.languages || {});
	// eslint-disable-next-line max-len
	const siteLanguagesList = computed<Array<SiteLanguage & { locale: string }>>(() => Object.entries(siteLanguages.value)
		.filter(([locale]) => locale !== SYSTEM_LOCALE)
		.map(([locale, languageData]) => ({
			...languageData,
			locale,
		})) ?? []);

	const hasLanguages = computed<boolean>(() => siteLanguagesList.value.length > 0);

	const currentSiteLanguage = computed<SiteLanguage | Record<string, never>>(() => siteLanguages.value?.[currentLocale.value] || {});

	const defaultLanguagePages = computed<SitePages | Record<string, never>>(
		() => siteLanguages.value?.[defaultLocale.value]?.pages || {},
	);

	const siteStyles = computed<SiteStyles | Record<string, never>>(() => site.value?.styles || {});
	const siteFonts = computed(() => siteStyles.value?.font || {});

	const siteForms = computed<SiteForms | Record<string, never>>(() => site.value?.forms || {});
	const privatePage = computed<SitePage | undefined>(() => Object.values(siteLanguages.value[SYSTEM_LOCALE].pages)
		.find(({ type }) => type === PAGE_TYPE_PRIVATE));

	const sitePages = computed<SitePages | Record<string, never>>(() => {
		if (currentLocale.value === SYSTEM_LOCALE) {
			return currentSiteLanguage.value.pages || {};
		}

		return {
			[PAGE_ID_PRIVATE]: {
				...privatePage.value,
			},
			...currentSiteLanguage.value.pages,
		};
	});
	const siteBlocks = computed<SiteBlocks | Record<string, never>>(() => currentSiteLanguage.value.blocks || {});
	const siteElements = computed<SiteElements | Record<string, never>>(() => currentSiteLanguage.value.elements || {});
	const headerBlock = computed(() => siteBlocks.value.header);
	const footerBlock = computed(() => Object.values(siteBlocks.value).find((block) => block.slot === 'footer'));
	const doesFooterExist = computed(() => !!footerBlock.value);
	const doesPageIdAlreadyExist = (pageId: string) => Object.keys(sitePages.value).includes(pageId);

	const currentPage = computed<SitePage | undefined>(() => sitePages.value[currentPageId.value]);
	const isCurrentPageTypeBlog = computed(() => currentPage.value?.type === PAGE_TYPE_BLOG);
	const isCurrentPagePrivate = computed(() => currentPage.value?.type === PAGE_TYPE_PRIVATE);
	const isCurrentPageTypeEcommerceProduct = computed(() => currentPage.value?.type === PAGE_TYPE_ECOMMERCE_PRODUCT);

	const currentBlock = computed<SiteBlock | undefined>(() => siteBlocks.value[currentBlockId.value]);
	const currentBlockType = computed(() => currentBlock.value?.type);
	const currentBlockSettings = computed(() => currentBlock.value?.settings);
	const currentBlockStyles = computed(() => currentBlockSettings.value?.styles);
	const currentBlockSlot = computed(() => currentBlock.value?.slot);

	const currentElement = computed(() => siteElements.value[currentElementId.value]);
	const currentElementContent = computed(() => currentElement.value?.content);
	const currentElementSettings = computed(() => currentElement.value?.settings);
	const currentElementStyles = computed(() => currentElementSettings.value?.styles);
	const currentElementType = computed(() => currentElement.value?.type);
	const currentElementBlockId = computed(() => getBlockIdByElementId({
		elementId: currentElementId.value,
		siteBlocks: siteBlocks.value,
	}) || '');
	const currentElementBlock = computed(() => siteBlocks.value[currentElementBlockId.value] || '');
	const currentElementBlockType = computed(() => currentElementBlock.value?.type);
	const isCurrentPageEmpty = computed(() => currentPage.value && currentPage.value?.blocks?.length === 0);
	const isPageSlugUnique = ({
		slug,
		slugPageId,
	// eslint-disable-next-line max-len
	}: { slug: string; slugPageId: string }) => !Object.entries(sitePages.value).some(([pageId, page]) => pageId !== slugPageId && page.slug === slug);

	const getElementBLockId = (elementId: string) => Object.keys(siteBlocks.value)
		.find((blockId) => siteBlocks.value[blockId].components?.includes(elementId));

	const siteNav = computed<SiteNavItem[]>(() => currentSiteLanguage.value.nav || []);
	const isNavHidden = computed(() => currentSiteLanguage.value.isNavHidden || false);

	const blogReadingTimeText = computed(() => currentSiteLanguage.value.blogReadingTimeText || '');

	const homePageId = computed(() => currentSiteLanguage.value.homePageId);
	const siteHomePage = computed<SitePage>(() => sitePages.value[homePageId.value]);
	const siteHomePageTitle = computed(() => (siteHomePage.value?.meta as any)?.title || siteHomePage.value.name);

	const ecommerceProductPages = computed<SitePages | Record<string, never>>(() => filterObject(
		defaultLanguagePages,
		({ value }: { value: SitePage }) => value.type === PAGE_TYPE_ECOMMERCE_PRODUCT,
	));
	const ecommerceShoppingCart = computed<SiteEcommerceShoppingCart | undefined>(() => site.value?.ecommerceShoppingCart);

	const blogCategories = computed(() => site?.value?.blogCategories || {});
	const isLanguageSwitcherHidden = computed(() => headerBlock.value?.settings?.isLanguageSwitcherHidden);

	const defaultPages = computed<SitePages>(() => filterObject(
		sitePages.value,
		({ value }: { value: SitePage }) => value.type === 'default',
	));

	const blogPages = computed<SitePages>(() => filterObject(
		sitePages.value,
		({ value }: { value: SitePage }) => value.type === 'blog',
	));

	const ecommerceLocaleProductPages = computed<SitePages>(() => filterObject(
		sitePages.value,
		({ value }: { value: SitePage }) => value.type === PAGE_TYPE_ECOMMERCE_PRODUCT,
	));

	const draftBlogPages = computed<SitePages>(() => filterObject(
		blogPages.value,
		({ value }: { value: SitePage }) => value.isDraft && !value.isScheduled,
	));

	const scheduledBlogPages = computed<SitePages>(() => filterObject(
		blogPages.value,
		({ value }: { value: SitePage }) => value.isScheduled,
	));

	const publishedBlogPages = computed<SitePages>(() => filterObject(
		blogPages.value,
		({ value }: { value: SitePage }) => !value.isDraft && !value.isScheduled,
	));

	const builderCompletedSteps = computed(() => site.value?.builderCompletedSteps);

	const isPrivateModeActive = computed(() => siteMeta.value?.isPrivateModeActive);

	const aiSalesAssistant = computed(() => siteMeta.value?.aiSalesAssistant);

	return {
		currentLocale,
		site,
		siteLanguages,
		siteLanguagesList,
		hasLanguages,
		currentSiteLanguage,
		siteBlocks,
		siteElements,
		sitePages,
		siteNav,
		isNavHidden,
		blogReadingTimeText,
		hResourceId,
		homePageId,
		ecommerceProductPages,
		defaultLocale,
		ecommerceShoppingCart,
		siteForms,
		siteStyles,
		siteTemplate,
		hasGeneratedTemplate,
		hasImportedTemplate,
		hasBlankTemplate,
		templateType,
		siteHomePageTitle,
		siteFonts,
		currentPage,
		currentBlock,
		currentBlockType,
		currentBlockSettings,
		currentBlockStyles,
		currentBlockSlot,
		currentElement,
		currentElementContent,
		currentElementSettings,
		currentElementStyles,
		currentElementType,
		currentElementBlockId,
		currentElementBlock,
		currentElementBlockType,
		getElementBLockId,
		headerBlock,
		footerBlock,
		doesFooterExist,
		doesPageIdAlreadyExist,
		isCurrentPageTypeBlog,
		isCurrentPageEmpty,
		isCurrentPagePrivate,
		isCurrentPageTypeEcommerceProduct,
		isPageSlugUnique,
		blogCategories,
		isLanguageSwitcherHidden,
		defaultPages,
		blogPages,
		ecommerceLocaleProductPages,
		draftBlogPages,
		scheduledBlogPages,
		publishedBlogPages,
		builderCompletedSteps,
		siteMeta,
		isPrivateModeActive,
		aiSalesAssistant,
	};
};
