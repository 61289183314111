<template>
	<div class="sidebar-controls">
		<div class="sidebar-controls__actions">
			<template v-if="isGamificationVisible">
				<BuilderSidebarAction
					:is-active="activeDrawer === DRAWER_GAMIFICATION"
					:title="t('builder.gamificationTitle')"
					class="sidebar-controls__gamification-button"
					@action-click="toggleDrawer(DRAWER_GAMIFICATION)"
				>
					<template #beforeTitle>
						<Transition name="fade">
							<CircularProgressBar
								:percentage="progressPercentage"
								:is-loading="!achievements.length"
								class="sidebar-controls__progress-bar"
							>
								<Icon
									name="checklist"
									dimensions="16px"
								/>
							</CircularProgressBar>
						</Transition>
						<AchievementCompletedTooltip
							v-if="activeDrawer !== DRAWER_GAMIFICATION && isAchievementCompleteTooltipShown"
							@close="handleGamificationTooltipClose"
						/>
					</template>
				</BuilderSidebarAction>
				<GamificationStartTooltip
					v-if="isGamificationTooltipVisible"
					@action-click="toggleDrawer(DRAWER_GAMIFICATION)"
				/>
			</template>
			<BuilderSidebarAction
				:is-active="activeDrawer === DRAWER_ADD_ELEMENT"
				:title="t('builder.addElementDrawer.title')"
				icon="add_circle"
				@action-click="toggleDrawer(DRAWER_ADD_ELEMENT)"
			/>
			<BuilderSidebarAction
				v-if="isMobileScreen && !siteStore.isCurrentPageTypeEcommerceProduct"
				:is-active="activeDrawer === DRAWER_ADD_SECTION"
				:title="t('builder.addSectionDrawer.title')"
				icon="view_day"
				@action-click="handleAddSectionActionClick"
			/>
			<BuilderSidebarAction
				:is-active="activeDrawer === DRAWER_MULTI_PAGE"
				:title="t('builder.siteMenu.title')"
				icon="layers"
				@action-click="toggleDrawer(DRAWER_MULTI_PAGE)"
			/>
			<BuilderSidebarAction
				:is-active="activeDrawer === DRAWER_USER_STYLES"
				:title="t('builder.userStyles.drawerLabel')"
				custom-icon="sidebar-styles"
				@action-click="toggleDrawer(DRAWER_USER_STYLES)"
			/>
			<BuilderSidebarAction :title="t('common.aiTools')">
				<template #trigger>
					<BuilderSidebarAiPopup
						:is-active="activePopup === 'aiPopup'"
						icon="auto_awesome"
						:title="t('common.aiTools')"
						@action-click="setOpenPopup('aiPopup')"
						@close-popup="setOpenPopup(null)"
					/>
				</template>
			</BuilderSidebarAction>
			<ZyroSeparator class="separator separator--with-margin" />
			<BuilderSidebarAction
				v-if="hasBlog || isMobileScreen"
				:is-active="activeDrawer === DRAWER_BLOG"
				:title=" t('builder.blog.blogDrawer.title')"
				icon="checkbook"
				@action-click="toggleDrawer(DRAWER_BLOG)"
			/>
			<BuilderSidebarAction
				:is-active="isStoreDrawerActive"
				:title="t('builder.onlineStore')"
				icon="shopping_cart"
				@action-click="handleSidebarStoreActionClick"
			/>
			<ZyroSeparator
				v-if="isMobileScreen"
				class="separator separator--with-margin"
			/>
			<BuilderSidebarAction
				v-if="isMobileScreen"
				:title="t('builder.analytics')"
			>
				<template #trigger>
					<BuilderSidebarSettingsPopup
						:title="t('builder.analytics')"
						icon="finance"
						@action-click="openAnalytics"
					/>
				</template>
			</BuilderSidebarAction>
			<BuilderSidebarAction
				v-if="isMobileScreen"
				:title="t('builder.sidebarFeedback.message')"
			>
				<template #trigger>
					<BuilderSidebarFeedbackPopup
						:is-active="activePopup === BUILDER_NPS_SIDEBAR_POPUP"
						:title="t('builder.sidebarFeedback.message')"
						@action-click="setOpenPopup(BUILDER_NPS_SIDEBAR_POPUP)"
						@close-popup="setOpenPopup(null)"
					/>
				</template>
			</BuilderSidebarAction>
			<BuilderSidebarAction
				v-if="isMobileScreen"
				:title="t('builder.helpAndResources')"
			>
				<template #trigger>
					<BuilderSidebarSettingsPopup
						:title="t('builder.helpAndResources')"
						icon="help"
						@action-click="openHelpDrawer"
					/>
				</template>
			</BuilderSidebarAction>
			<ZyroSeparator
				v-if="isMobileScreen"
				class="separator separator--with-margin"
			/>
			<BuilderSidebarAction :title="t('builder.sidebarMoreSettingsLabel')">
				<template #trigger>
					<BuilderSidebarSettingsPopup
						:is-active="activePopup === 'builderSettings'"
						:title="t('builder.sidebarMoreSettingsLabel')"
						icon="more_horiz"
						@action-click="setOpenPopup('builderSettings')"
						@close-popup="setOpenPopup(null)"
					/>
				</template>
			</BuilderSidebarAction>
			<template v-if="!!checkoutSlugPageId && isMobileScreen">
				<ZyroSeparator class="separator" />
				<BuilderSidebarAction :title="t('builder.payAttention')">
					<template #trigger>
						<BuilderSidebarWarningPopup
							:is-active="activePopup === 'warningPopup'"
							:title="t('builder.payAttention')"
							:page-id="checkoutSlugPageId"
							@action-click="setOpenPopup('warningPopup')"
							@close-popup="setOpenPopup(null)"
						/>
					</template>
				</BuilderSidebarAction>
			</template>
		</div>
		<div v-if="!isMobileScreen">
			<BuilderSidebarAction
				v-if="!!checkoutSlugPageId"
				:title="t('builder.payAttention')"
			>
				<template #trigger>
					<BuilderSidebarWarningPopup
						:is-active="activePopup === 'warningPopup'"
						:title="t('builder.payAttention')"
						:page-id="checkoutSlugPageId"
						@action-click="setOpenPopup('warningPopup')"
						@close-popup="setOpenPopup(null)"
					/>
				</template>
			</BuilderSidebarAction>
			<BuilderSidebarAction :title="t('builder.sidebarFeedback.message')">
				<template #trigger>
					<BuilderSidebarFeedbackPopup
						:is-active="activePopup === BUILDER_NPS_SIDEBAR_POPUP"
						:title="t('builder.sidebarFeedback.message')"
						@action-click="setOpenPopup(BUILDER_NPS_SIDEBAR_POPUP)"
						@close-popup="setOpenPopup(null)"
					/>
				</template>
			</BuilderSidebarAction>
		</div>
	</div>
</template>
<script setup>
import {
	DRAWER_ADD_ELEMENT,
	DRAWER_ADD_SECTION,
	DRAWER_BLOG,
	DRAWER_MULTI_PAGE,
	DRAWER_USER_STYLES,
	DRAWER_GAMIFICATION,
	DRAWER_MANAGE_STORE,
	DRAWER_ADD_ONLINE_STORE,
	MODAL_ADD_BLOCK,
	DRAWER_ANALYTICS,
	DRAWER_HELP_AND_RESOURCES,
	GAMIFICATION_PRODUCT_TYPES,
} from '@/constants';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';
import Icon from '@/components/global/Icon.vue';
import BuilderSidebarAction from '@/components/builder-view/sidebar/BuilderSidebarAction.vue';
import BuilderSidebarAiPopup from '@/components/builder-view/sidebar/BuilderSidebarAiPopup.vue';
import BuilderSidebarFeedbackPopup from '@/components/builder-view/sidebar/BuilderSidebarFeedbackPopup.vue';
import BuilderSidebarSettingsPopup from '@/components/builder-view/sidebar/BuilderSidebarSettingsPopup.vue';
import BuilderSidebarWarningPopup from '@/components/builder-view/sidebar/BuilderSidebarWarningPopup.vue';
import CircularProgressBar from '@/components/reusable-components/CircularProgressBar.vue';
import AchievementCompletedTooltip from '@/components/onboarding/AchievementCompletedTooltip.vue';
import EventLogApi from '@/api/EventLogApi';
import {
	CLOSE_DRAWER,
	TOGGLE_DRAWER,
	OPEN_MODAL,
} from '@/store/builder/gui';
import { useGamification } from '@/use/useGamification';
import { useSidebar } from '@/use/useSidebar';
import {
	BUILDER_NPS_TRIGGER_SELECTOR,
	BUILDER_NPS_SIDEBAR_POPUP,
} from '@/components/builder-nps/npsSelectors';
import { useI18n } from 'vue-i18n';
import { useSiteStore } from '@/stores/siteStore';
import {
	computed,
	ref,
	watch,
} from 'vue';
import { useStore } from 'vuex';
import GamificationStartTooltip from '@/components/onboarding/GamificationStartTooltip.vue';
import { useNpsStore } from '@/stores';

const { t } = useI18n();
const {
	getters,
	state,
	dispatch,
} = useStore();
const siteStore = useSiteStore();

const {
	isGamificationVisible,
	achievements,
	completedAchievementsCount,
	lastCompletedAchievement,
} = useGamification();

const { closeSidebar } = useSidebar();
const npsStore = useNpsStore();

const isGamificationTooltipVisible = computed(() => state.redirect.params.isGamificationVisible);
const pages = computed(() => getters.sitePages);
const checkoutSlugPageId = computed(() => Object.keys(pages.value).find((pageId) => pages.value[pageId].slug === 'checkout' && pageId !== 'home'));

const activePopup = ref('');
const isAchievementCompleteTooltipShown = ref(false);

const isNpsVisible = computed(() => npsStore.isNpsVisible);
const currentBlockId = computed(() => state.currentBlockId);
const progressPercentage = computed(() => (completedAchievementsCount.value / achievements.value.length) * 100);
const isStoreDrawerActive = computed(() => (
	state.gui.activeDrawer === DRAWER_ADD_ONLINE_STORE
  || state.gui.activeDrawer === DRAWER_MANAGE_STORE
));
const hasBlog = computed(() => getters['blog/hasBlog']);
const isStoreTypeZyro = computed(() => getters['ecommerce/isStoreTypeZyro']);
const activeDrawer = computed(() => state.gui.activeDrawer);
const isMobileScreen = computed(() => state.gui.isMobileScreen);

const handleGamificationTooltipClose = () => {
	isAchievementCompleteTooltipShown.value = false;
};

const setOpenPopup = (value) => {
	if (activeDrawer.value && value !== null) {
		dispatch(`gui/${CLOSE_DRAWER}`);
	}

	if (activePopup.value === value) {
		activePopup.value = '';
	} else {
		const eventNameMap = {
			aiPopup: 'website_builder.ai_tools.enter',
			builderSettings: 'website_builder.website_settings.enter',
			[BUILDER_NPS_SIDEBAR_POPUP]: 'website_builder.feedback.enter',
		};

		if (eventNameMap[value]) {
			EventLogApi.logEvent({
				eventName: eventNameMap[value],
			});
		}

		activePopup.value = value;

		dispatch('unselectCurrentElement');
	}
};

const toggleDrawer = (drawer) => {
	dispatch(`gui/${TOGGLE_DRAWER}`, drawer);
};

const handleSidebarStoreActionClick = () => {
	if (isStoreTypeZyro.value) {
		toggleDrawer(DRAWER_MANAGE_STORE);
	} else {
		toggleDrawer(DRAWER_ADD_ONLINE_STORE);
	}
};

const handleAddSectionActionClick = () => {
	dispatch(`gui/${OPEN_MODAL}`, {
		name: MODAL_ADD_BLOCK,
		settings: {
			previousBlockId: currentBlockId.value,
		},
	});
	dispatch(`gui/${CLOSE_DRAWER}`);
	closeSidebar();
};

const openAnalytics = () => {
	dispatch('gui/OPEN_DRAWER', {
		id: DRAWER_ANALYTICS,
	});
};

const openHelpDrawer = () => {
	dispatch('gui/OPEN_DRAWER', {
		id: DRAWER_HELP_AND_RESOURCES,
	});
};

watch(completedAchievementsCount, (newCount) => {
	if (!lastCompletedAchievement.value || lastCompletedAchievement.value.type === GAMIFICATION_PRODUCT_TYPES.ECOMMERCE) {
		return;
	}

	const lastCompletedAchievementKeys = Object.keys(lastCompletedAchievement.value);

	// tooltip shown only when one step is already done
	if (lastCompletedAchievementKeys.length && newCount > 1) {
		isAchievementCompleteTooltipShown.value = true;
	}
});

watch(isNpsVisible, (newIsNpsVisible) => {
	if (newIsNpsVisible && activePopup.value !== BUILDER_NPS_SIDEBAR_POPUP) {
		document.querySelector(`[data-trigger-reference="${BUILDER_NPS_TRIGGER_SELECTOR}"`).click();
	}
});

</script>
<style lang="scss" scoped>

.sidebar-controls {
  display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: 4px;

	// Fixes the issue with overflowing content on small screens
	// That is caused by BuilderPreview unselectCurrentElement call on screen resize
	@media screen and (max-width: $media-mobile) {
		overflow-x: hidden;
		overflow-y: auto;
		justify-content: unset;
		padding: 0;
	}

  &__action-pill {
		position: absolute;
		top: 4px;
		right: 5px;
		z-index: $z-index-layout-sidebar;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 16px;
		height: 16px;
		padding: 0 0 0 1px;
		border-radius: 50%;
	}

  &__progress-bar {
    &--with-margin {
      margin-right: -4px;
    }
  }

	&__gamification-button {
			:deep(.zyro-button) {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 8px;

				&.active {
					.circular-progress-bar::before {
						background-color: $color-light;
					}

					.circular-progress-bar__center-mask {
						background-color: $color-primary-light;
					}
				}

				&:not(.active){
					&:hover,
					&:focus {
						.circular-progress-bar::before {
							background-color: $color-light;
						}

						.circular-progress-bar__center-mask {
							background-color: $color-gray-light;
						}
					}
				}
			}
	}
}

.separator {
	width: calc(100% - 16px);
	height: 1px;
	background-color: $color-gray-border;
	transition: 0.2s ease-in-out;
	margin: 4px auto 8px;
}

</style>
