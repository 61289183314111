<template>
	<Popup
		:target-ref="targetRef || drawerDomElement"
		:portal-selector="SELECTOR_DATA_PORTAL_APP"
		auto-update
		@click-outside="closeAndDiscardChanges"
	>
		<div class="page-settings">
			<h2 class="page-settings__title">
				{{ $t('builder.pageSettingsModal.title') }}
			</h2>
			<HostingerButton
				v-qa="'modal-btn-close'"
				button-type="plain"
				class="page-settings__close"
				:title="$t('common.close')"
				@click="closeAndDiscardChanges"
			>
				<template #icon>
					<Icon name="close" />
				</template>
			</HostingerButton>
			<div class="page-settings__tabs">
				<ZyroTabs
					:tabs="tabs"
					:current-tab="currentTab"
					@update:current-tab="currentTab = $event"
				/>
			</div>
			<div class="page-settings__content">
				<PageSettingsGeneral
					v-if="currentTab?.id === TABS.GENERAL"
					:page-type="pageType"
					:page-id="pageId"
					:is-item-hidden="isPageHiddenBeforeEdit"
					@set-is-item-hidden="isPageHiddenBeforeEdit = $event"
					@is-valid="error = !$event"
				/>
				<PageSettingsSocialImage
					v-if="currentTab?.id === TABS.SOCIAL_IMAGE && !isPageTypeEcommerceProduct"
					:og-image-origin="ogImageOrigin"
					:og-image-path="ogImagePath"
					:og-image-alt="ogImageAlt"
					@og-image-change="handleOgImageChange"
				/>
				<PagePasswordSettings
					v-if="currentTab?.id === TABS.PASSWORD_PROTECTION"
					:page-id="pageId"
					:password="pagePassword"
					:heading-text="pagePasswordHeadingText"
					:subheading-text="pagePasswordSubheadingText"
					:default-password-page-texts="defaultPasswordPageTexts"
					:button-text="pagePasswordButtonText"
					:placeholder-text="pagePasswordInputPlaceholderText"
					:back-text="pagePasswordBackText"
					:is-password-changed="isPasswordChanged"
					:password-design="pagePasswordDesign"
					:is-password-enabled="isPasswordEnabled"
					@password-change="updatePasswordValue"
					@design-change="updatePasswordDesignValue"
					@toggle-password-enable="togglePasswordEnable"
					@password-page-text-change="updatePasswordPageText"
				/>
			</div>
			<div class="page-settings__footer">
				<HostingerButton
					v-qa="'pagesettings-button-cancel'"
					button-type="text"
					@mousedown="closeAndDiscardChanges"
				>
					{{ $t('common.cancel') }}
				</HostingerButton>
				<HostingerButton
					v-qa="'pagesettings-button-save'"
					:disabled="error"
					@mousedown="handleSaveClick"
				>
					{{ $t('common.save') }}
				</HostingerButton>
			</div>
		</div>
	</Popup>
</template>

<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import Popup from '@/components/global/Popup.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroTabs from '@/components/global/ZyroTabs.vue';

import cloneDeep from 'lodash.clonedeep';
import { useStore } from 'vuex';

import {
	TABS,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	PAGE_TYPE_DEFAULT,
	PAGE_PASSWORD_DESIGN_TYPE_DEFAULT,
	TabsType,
} from '@zyro-inc/site-modules/constants';
import {
	SitePage,
	SiteNavItem,
} from '@hostinger/builder-schema-validator';
import { SELECTOR_DATA_PORTAL_APP } from '@/constants';
import PageSettingsGeneral from '@/components/builder-controls/page-settings/PageSettingsGeneral.vue';
import PageSettingsSocialImage from '@/components/builder-controls/page-settings/PageSettingsSocialImage.vue';
import PagePasswordSettings from '@/components/builder-controls/page-settings/PagePasswordSettings.vue';
import { BUILDER_SIDEBAR_SELECTOR } from '@/components/onboarding/onboardingSelectors';
import EventLogApi from '@/api/EventLogApi';

import {
	ref,
	computed,
	watch,
	onBeforeUnmount,
	nextTick,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { generateSha256 } from '@zyro-inc/site-modules/utils/hashPassword';
import { updateAiGeneratedImagePath } from '@/utils/urlValidators';
import { useSiteStore } from '@/stores/siteStore';

type Tab = { id: TabsType; title: string; };

type Props = {
	pageId: string;
	openTab?: TabsType;
	targetRef?: HTMLElement;
};

type Emits = {
	close: [];
};

const props = withDefaults(defineProps<Props>(), {
	openTab: TABS.GENERAL,
});
const emit = defineEmits<Emits>();
const { t } = useI18n();
const {
	state,
	getters,
	dispatch,
} = useStore();

const siteStore = useSiteStore();

const currentTab = ref<Tab | null | undefined>(null);

const pageData = computed(() => getters.sitePages[props.pageId]);
const shouldPageBeNoIndexed = ref();
const pagePassword = ref('');
const pagePasswordDesign = ref('');
const isPasswordChanged = ref(false);
const pagePasswordHeadingText = ref('');
const pagePasswordSubheadingText = ref('');
const pagePasswordInputPlaceholderText = ref('');
const pagePasswordButtonText = ref('');
const pagePasswordBackText = ref('');
const isPasswordEnabled = ref(false);
const pageBeforeEdit = ref<SitePage | null>(null);
const pageNavigationItemBeforeEdit = ref(null);
const error = ref(false);
const isPageHiddenBeforeEdit = ref(false);
const ogImageOrigin = ref<string | null>(null);
const ogImagePath = ref<string | null>(null);
const ogImageAlt = ref('');
const hasSavedChanges = ref(false);

const sitePages = computed(() => getters.sitePages);
const getItemByPageId = (pageId: string) => getters['navigation/getItemByPageId'](pageId);
const pageToEdit = computed(() => sitePages.value[props.pageId]);
const pageType = computed(() => sitePages.value[props.pageId]?.type);
const isDefaultPage = computed(() => pageType.value === PAGE_TYPE_DEFAULT);
const isPageTypeEcommerceProduct = computed(() => pageType.value === PAGE_TYPE_ECOMMERCE_PRODUCT);
const tabs = computed(() => [
	...(isPageTypeEcommerceProduct.value ? [] : [
		{
			id: TABS.GENERAL,
			title: t('common.general'),
		},
	]),
	...(isPageTypeEcommerceProduct.value ? [] : [
		{
			id: TABS.SOCIAL_IMAGE,
			title: t('common.socialImage'),
		},
	]),
	{
		id: TABS.PASSWORD_PROTECTION,
		title: 'Password',
	},
]);
const pageMeta = computed(() => pageToEdit.value?.meta ?? {});
const pageNavLink = computed(() => getItemByPageId(props.pageId));
const drawerDomElement = computed(() => document.querySelector(`[data-popper-reference="${BUILDER_SIDEBAR_SELECTOR}"]`));
const defaultPasswordPageTexts = computed(() => ({
	heading: t('builder.passwordPageDefaultHeading'),
	subheading: t('builder.passwordPageDefaultSubheading'),
	inputPlaceholder: t('builder.passwordPageDefaultInputPlaceholder'),
	button: t('builder.passwordPageDefaultButtonText'),
	backText: t('builder.passwordPageDefaultBackText'),
}));

const updateInternalLinks = (payload: { oldLink: string; newLink: string }) => dispatch('updateInternalLinks', payload);
const mergePageData = (payload: { pageId: string; pageData: SitePage | null }) => dispatch('mergePageData', payload);
const setItemData = (payload: { data: SiteNavItem }) => dispatch('navigation/setItemData', payload);
const changeItemVisibility = (payload: { itemId: string; isHidden: boolean; }) => dispatch('navigation/changeItemVisibility', payload);

const handleOgImageChange = ({
	origin,
	path,
	alt,
	url,
}: {
  origin: string;
  path: string;
  alt: string;
  url: string;
 }) => {
	const validPath = updateAiGeneratedImagePath(url, path);

	ogImageOrigin.value = origin;
	ogImagePath.value = validPath;
	ogImageAlt.value = alt;
};

const discardChanges = (pageId: string) => {
	if (hasSavedChanges.value) {
		return;
	}

	mergePageData({
		pageId,
		pageData: pageBeforeEdit.value,
	});

	if (pageNavigationItemBeforeEdit.value && isDefaultPage.value) {
		setItemData({
			data: pageNavigationItemBeforeEdit.value,
		});
	}
};

const closeAndDiscardChanges = () => {
	discardChanges(props.pageId);
	emit('close');
};

const handleSaveClick = async () => {
	if (isPasswordEnabled.value && !pagePassword.value) {
		currentTab.value = tabs.value.find(({ id }) => id === TABS.PASSWORD_PROTECTION);
		isPasswordChanged.value = true;

		await nextTick();

		// scroll to password input selected by html id password-input
		const passwordInput = document.getElementById('page-password');

		if (passwordInput) {
			passwordInput.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}

		return;
	}

	if (isDefaultPage.value && isPageHiddenBeforeEdit.value !== !!pageNavLink.value.isHidden) {
		changeItemVisibility({
			itemId: pageNavLink.value.navItemId,
			isHidden: isPageHiddenBeforeEdit.value,
		});
	}

	if (pageBeforeEdit.value?.slug !== pageToEdit.value.slug) {
		updateInternalLinks({
			oldLink: `/${pageBeforeEdit.value?.slug}`,
			newLink: `/${pageToEdit.value.slug}`,
		});
	}

	if (isPageTypeEcommerceProduct.value) {
		EventLogApi.logEvent({
			eventName: 'website_builder.ecomm_page_settings.saved',
		});
	}

	if (!isPageTypeEcommerceProduct.value && pageBeforeEdit.value?.meta?.ogImagePath !== ogImagePath.value) {
		mergePageData({
			pageId: props.pageId,
			pageData: {
				meta: {
					ogImageOrigin: ogImageOrigin.value,
					ogImagePath: ogImagePath.value,
					ogImageAlt: ogImageAlt.value,
				},
			},
		});
	}

	// Password hashing
	let hashedPassword = '';

	if (isPasswordEnabled.value) {
		hashedPassword = isPasswordChanged.value ? await generateSha256(pagePassword.value) : pageMeta.value.password;
	}

	if (isPasswordChanged.value) {
		EventLogApi.logEvent({
			eventName: 'website_builder.password_setup.saved',
		});
	}

	isPasswordChanged.value = false;

	mergePageData({
		pageId: props.pageId,
		pageData: {
			meta: {
				noindex: shouldPageBeNoIndexed.value,
				passwordHeadingText: pagePasswordHeadingText.value,
				passwordSubheadingText: pagePasswordSubheadingText.value,
				passwordButtonText: pagePasswordButtonText.value,
				passwordBackText: pagePasswordBackText.value,
				passwordPlaceholderText: pagePasswordInputPlaceholderText.value,
				passwordDesign: pagePasswordDesign.value,
				password: hashedPassword,
			},
		},
	});

	hasSavedChanges.value = true;
	emit('close');
};

const updatePasswordValue = (newValue: string) => {
	pagePassword.value = newValue;
	isPasswordChanged.value = true;
	shouldPageBeNoIndexed.value = true;
};

const updatePasswordDesignValue = (newValue: string) => {
	pagePasswordDesign.value = newValue;
};

const updatePasswordPageText = ({
	newValue,
	type,
}: { newValue: string; type: 'heading' | 'subheading' | 'inputPlaceholder' | 'button' | 'backText' }) => {
	if (type === 'heading') {
		pagePasswordHeadingText.value = newValue;
	}

	if (type === 'subheading') {
		pagePasswordSubheadingText.value = newValue;
	}

	if (type === 'inputPlaceholder') {
		pagePasswordInputPlaceholderText.value = newValue;
	}

	if (type === 'button') {
		pagePasswordButtonText.value = newValue;
	}

	if (type === 'backText') {
		pagePasswordBackText.value = newValue;
	}
};

const togglePasswordEnable = (newValue: boolean) => {
	isPasswordEnabled.value = newValue;

	if (newValue) {
		EventLogApi.logEvent({
			eventName: 'website_builder.password_setup.enabled',
		});

		return;
	}

	EventLogApi.logEvent({
		eventName: 'website_builder.password_setup.disabled',
	});

	pagePassword.value = '';
	pagePasswordDesign.value = PAGE_PASSWORD_DESIGN_TYPE_DEFAULT;
	pagePasswordHeadingText.value = defaultPasswordPageTexts.value.heading;
	pagePasswordSubheadingText.value = defaultPasswordPageTexts.value.subheading;
	pagePasswordInputPlaceholderText.value = defaultPasswordPageTexts.value.inputPlaceholder;
	pagePasswordButtonText.value = defaultPasswordPageTexts.value.button;
	pagePasswordBackText.value = defaultPasswordPageTexts.value.backText;

	const {
		passwordHeadingText,
		passwordSubheadingText,
		passwordPlaceholderText,
		passwordButtonText,
		passwordBackText,
		passwordDesign,
		password,
		...restMeta
	} = pageData.value?.meta ?? {};

	// Remove password related data from page meta data entirely
	siteStore.setSitePageData({
		pageId: props.pageId,
		locale: state.currentLocale,
		data: {
			...pageData.value,
			meta: {
				...restMeta,
			},
		},
	});
};

const setInitialTab = () => {
	currentTab.value = tabs.value.find(({ id }) => id === props.openTab);
};

const setInitialValues = () => {
	setInitialTab();
	pageBeforeEdit.value = cloneDeep(pageToEdit.value);
	pageNavigationItemBeforeEdit.value = cloneDeep(pageNavLink.value);
	isPageHiddenBeforeEdit.value = !!pageNavLink.value?.isHidden;

	if (!isPageTypeEcommerceProduct.value) {
		ogImageOrigin.value = pageMeta.value.ogImageOrigin || null;
		ogImagePath.value = pageMeta.value.ogImagePath || null;
		ogImageAlt.value = pageMeta.value.ogImageAlt || '';
	}

	shouldPageBeNoIndexed.value = pageMeta.value?.noindex || false;

	// Page password related data
	pagePassword.value = pageMeta.value.password ?? '';
	isPasswordEnabled.value = !!pagePassword.value;
	pagePasswordDesign.value = pageMeta.value.passwordDesign ?? PAGE_PASSWORD_DESIGN_TYPE_DEFAULT;
	pagePasswordHeadingText.value = pageMeta.value.passwordHeadingText ?? defaultPasswordPageTexts.value.heading;
	pagePasswordSubheadingText.value = pageMeta.value.passwordSubheadingText ?? defaultPasswordPageTexts.value.subheading;
	pagePasswordButtonText.value = pageMeta.value.passwordButtonText ?? defaultPasswordPageTexts.value.button;
	pagePasswordInputPlaceholderText.value = pageMeta.value.passwordPlaceholderText ?? defaultPasswordPageTexts.value.inputPlaceholder;
	pagePasswordBackText.value = pageMeta.value.passwordBackText ?? defaultPasswordPageTexts.value.backText;
	isPasswordChanged.value = false;
};

setInitialValues();

watch(() => props.pageId, (_, previousPageId) => {
	discardChanges(previousPageId);
	setInitialValues();
});

watch(() => props.openTab, () => {
	setInitialTab();
});

onBeforeUnmount(() => {
	discardChanges(props.pageId);
});
</script>
<style lang="scss" scoped>
.page-settings {
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 420px;
	max-height: calc(100vh - 103px);
	cursor: default;
	background: $color-light;
	border-radius: $border-radius-large;
	box-shadow: 0 5px 5px rgba($color-dark, 0.05), 0 10px 30px rgba($color-dark, 0.2);
	transition: all 0.3s ease;

	&__title {
		padding: 16px 24px;
	}

	&__tabs {
		margin-top: -12px;
		padding: 0 24px;
	}

	&__close {
		position: absolute;
		right: 8px;
		top: 8px;
	}

	&__content {
		height: 100%;
		padding: var(--z-content-padding, 0 24px 8px 24px);
		overflow: auto;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		padding: 16px 24px;
		margin-top: auto;
		border-top: 1px solid $color-gray-border;
	}
}
</style>
